import React, { Fragment, useEffect, useState, useRef } from 'react'
import FootCta from '../components/FootCta/FootCta'
import ClientCta from '../components/ClientCta/ClientCta'
import ScrollAnimation from 'react-animate-on-scroll';
import { MetaInfo } from '../components/Seo/MetaInfo';

const Services = () => {
    const sections = [
        { id: 'strategy', title: 'Strategy' },
        { id: 'branding', title: 'Brand Design' },
        { id: 'design', title: 'Product Design' },
        { id: 'technology', title: 'Technology' },
        { id: 'market', title: 'Market' },
        { id: 'values', title: 'Values' },
        { id: 'models', title: 'Models' }
    ];

    const [activeId, setActiveId] = useState(sections[0].id);
    const handleScroll = () => {
        const scrollDistance = window.scrollY;

        let currentId = sections[0].id;
        sections.forEach((section) => {
            const element = document.getElementById(section.id);
            const offsetTop = element.offsetTop;
            const offsetHeight = element.offsetHeight;
            if (scrollDistance >= offsetTop - 1 && scrollDistance < offsetTop + offsetHeight - 1) {
                currentId = section.id;
            }
        });
        setActiveId(currentId);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Initial call to set the correct active link
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const navStyle = {
        navStickTop: '92px'
    }

    return (
        <Fragment>
            <MetaInfo page_name="services" />

            <section class="dl-page-banner dl-block dl-block-dark text-center">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12 dlsm-12 dllg-12">
                            <ScrollAnimation animateIn="fadeInUp">
                                <h1 class="dl-page-title font-body">DarkLion Studio Services</h1>
                                <h1 class="mT0 mB20 font-w-600">We research, we design, we develop, we market</h1>
                                <p class="font-l">We formulate strategic thinking to bridge the gap between business and design by providing <span class="line-break">creative business solutions.</span></p>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>

            <div class={'sticky-sub-nav services border-bottom-grey hidden-dlsm0'} >
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12 dlsm-12 dllg-12">
                            <nav>
                                <ul class="ul-none d-flex flex-jcc font-m m0">
                                    {sections.map((section, index) => (
                                        <li key={section.id}>
                                            <a href={`#${section.id}`} onClick={() => scrollToSection(section.id)} className={activeId === section.id ? 'active anchor-none' : 'anchor-none'}>
                                                {section.title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <section class="dl-block dl-scroll-block" id="strategy">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12 dlsm-12 dllg-12 text-left">
                            <div class="dl-service-card d-flex dlxss-flex-col dlxss-center">
                                <i class="dl-icon mR20 dlxss-m0">
                                    <svg class="amin-icon dark" width="90" height="90" viewBox="0 0 137 138" fill="none">
                                        <path class="amin-path" d="M89.9197 48.0763C102.358 60.515 111.026 74.23 115.04 86.2738C119.097 98.4444 118.234 108.267 112.753 113.748C107.272 119.229 97.4492 120.092 85.2786 116.035C73.2348 112.021 59.5198 103.353 47.0812 90.9149C34.6425 78.4762 25.9753 64.7612 21.9607 52.7174C17.9038 40.5468 18.7666 30.7239 24.2477 25.2428C29.7287 19.7618 39.5516 18.899 51.7223 22.9558C63.766 26.9704 77.4811 35.6377 89.9197 48.0763Z" />
                                        <path class="amin-path" d="M89.9198 90.9158C77.4811 103.354 63.7661 112.022 51.7223 116.036C39.5517 120.093 29.7288 119.23 24.2478 113.749C18.7667 108.268 17.9039 98.4453 21.9607 86.2747C25.9753 74.2309 34.6426 60.5159 47.0812 48.0773C59.5199 35.6386 73.2349 26.9714 85.2787 22.9568C97.4493 18.8999 107.272 19.7627 112.753 25.2438C118.234 30.7248 119.097 40.5477 115.04 52.7184C111.026 64.7621 102.358 78.4772 89.9198 90.9158Z" />
                                    </svg>
                                </i>

                                <div>
                                    <a class="d-flex anchor-none">
                                        <div class="">
                                            <h3>Strategy</h3>
                                            <p class="mB30 font-l dlxss-mB20">There are "n" number of strategies, approaches, & processes. We're here to help you implement the right strategy to design your digital business.</p>
                                        </div>
                                    </a>

                                    <div class="dl-row">
                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover dl-card-service">
                                                <h5 class="font-body m0">Brand Strategy</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>

                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover dl-card-service">
                                                <h5 class="font-body m0">Digital Product Design Strategy</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="dl-row">
                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover dl-card-service">
                                                <h5 class="font-body m0">Design Process Consultancy</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>

                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover dl-card-service">
                                                <h5 class="font-body m0">Design Thinking</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="dl-row">
                                        <div class="dlxs-12">
                                            <ScrollAnimation animateIn="fadeInUp">
                                                <img src={require('../assets/img/team/services/darklion-studio-strategy.jpg')} alt="DarkLion Studio Strategic Services" class="dl-img-responsive dl-pos-in mT50" />
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="dl-block dl-scroll-block dl-block-dark" id="branding">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12 dlsm-12 dllg-12 text-left">
                            <div class="dl-service-card d-flex dlxss-flex-col  dlxss-center">
                                <i class="dl-icon mR20 dlxss-m0">
                                    <svg class="amin-icon" width="90" height="90" viewBox="0 0 137 137" fill="none">
                                        <path class="amin-path" d="M34 93.5593L103 54.7737L34 10.5254" stroke="white" strokeWidth="4"></path>
                                        <path d="M35.168 127.474V46.7793L102.414 87.1268L35.168 127.474Z" stroke="white" strokeWidth="4"></path>
                                    </svg>
                                </i>

                                <div>
                                    <a class="d-flex anchor-none">
                                        <div class="">
                                            <h3>Brand Design</h3>
                                            <p class="mB30 font-l dlxss-mB20">We help you design brands that express rational and authentic stories about your vision and business goals and help you to connect with the right audience.</p>
                                        </div>
                                    </a>

                                    <div class="dl-row">
                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover dl-card-service">
                                                <h5 class="font-body m0">Brand Research</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>

                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover dl-card-service">
                                                <h5 class="font-body m0">Brand Development</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="dl-row">
                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover dl-card-service">
                                                <h5 class="font-body m0">Brand Activation</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>

                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover dl-card-service">
                                                <h5 class="font-body m0">Brand Management</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="dl-row">
                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover dl-card-service">
                                                <h5 class="font-body m0">Brand Analysis</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>

                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover dl-card-service">
                                                <h5 class="font-body m0">Website Design</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="dl-row">
                                        <div class="dlxs-12">
                                            <ScrollAnimation animateIn="fadeInUp">
                                                <img src={require('../assets/img/team/services/darklion-studio-web-design.jpg')} alt="DarkLion Studio Branding Services" class="dl-img-responsive dl-pos-in animate fadeInUp mT50" />
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="dl-block dl-scroll-block" id="design">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12 dlsm-12 dllg-12 text-left">
                            <div class="dl-service-card d-flex dlxss-flex-col dlxss-center">
                                <i class="dl-icon mR20 dlxss-m0">
                                    <svg class="amin-icon dark" width="100" height="100" viewBox="0 0 137 137" fill="none">
                                        <path class="amin-path" d="M24.1055 24L68.1028 112.849L113.382 24"></path>
                                        <path d="M13 56.4844L123.207 56.4844" stroke="black" strokeWidth="4"></path>
                                    </svg>
                                </i>

                                <div>
                                    <a class="d-flex anchor-none">
                                        <div class="">
                                            <h3>Digital Product Design</h3>
                                            <p class="mB30 font-l dlxss-mB20">We will help you design and shape your digital business idea at any stage of your product life-cycle from discovery to growth.</p>
                                        </div>

                                    </a>

                                    <div class="dl-row">
                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover focus-hover-dark dl-card-service">
                                                <h5 class="font-body m0">UI/UX Audit & Improvements</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>

                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover focus-hover-dark dl-card-service">
                                                <h5 class="font-body m0">UX Research</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="dl-row">
                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover focus-hover-dark dl-card-service">
                                                <h5 class="font-body m0">UX Analysis</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>

                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover focus-hover-dark dl-card-service">
                                                <h5 class="font-body m0">UX Writing</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="dl-row">
                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover focus-hover-dark dl-card-service">
                                                <h5 class="font-body m0">User Experience Design</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>

                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover focus-hover-dark dl-card-service">
                                                <h5 class="font-body m0">User Interface Design</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="dl-row">
                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover focus-hover-dark dl-card-service">
                                                <h5 class="font-body m0">Interaction Design</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>

                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover focus-hover-dark dl-card-service">
                                                <h5 class="font-body m0">Digital Prototyping</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="dl-row">
                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover focus-hover-dark dl-card-service">
                                                <h5 class="font-body m0">Enterprise Design System</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="dl-row">
                                        <div class="dlxs-12">
                                            <ScrollAnimation animateIn="fadeInUp">
                                                <img src={require('../assets/img/team/services/darklion-studio-design.jpg')} alt="DarkLion Studio Design Services" class="dl-img-responsive dl-pos-in " />
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="dl-block dl-scroll-block dl-block-dark" id="technology">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12 dlsm-12 dllg-12 text-left">
                            <div class="dl-service-card d-flex dlxss-flex-col  dlxss-center">
                                <i class="dl-icon mR20 dlxss-m0">
                                    <svg class="amin-icon" width="90" height="90" viewBox="0 0 137 137" fill="none">
                                        <path d="M111 24H24V112.5L111 24Z" stroke="white" strokeWidth="4" />
                                        <path class="amin-path" d="M112 112L24.5 24.5" stroke="white" strokeWidth="4" />
                                    </svg>
                                </i>

                                <div>
                                    <a class="d-flex anchor-none">
                                        <div class="">
                                            <h3>Technology</h3>
                                            <p class="mB30 font-l dlxss-mB20">Our user centric design process and design centric development helps you build the ideal product.</p>
                                        </div>
                                    </a>

                                    <div class="dl-row">
                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover focus-hover-dark dl-card-service">
                                                <h5 class="font-body m0">Mobile App Development</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>

                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover focus-hover-dark dl-card-service">
                                                <h5 class="font-body m0">Web App Development</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="dl-row">
                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover focus-hover-dark dl-card-service">
                                                <h5 class="font-body m0">CMS / Wordpress Development</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>

                                        <div class="dlmd-6 dlsm-12 dlxs-12">
                                            <div class="focus-hover focus-hover-dark dl-card-service">
                                                <h5 class="font-body m0">Growing...</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="dl-row">
                                        <div class="dlxs-12">
                                            <ScrollAnimation animateIn="fadeInUp">
                                                <img src={require('../assets/img/team/process/darklion-studio-process-develop.jpg')} alt="DarkLion Studio Technology Services" class="dl-img-responsive dl-pos-in" />
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="dl-block dl-scroll-block" id="market">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12 dlsm-12 dllg-12 text-left">
                            <div class="dl-service-card d-flex dlxss-flex-col  dlxss-center">
                                <i class="dl-icon mR20 dlxss-m0">
                                    <svg class="amin-icon dark" width="100" height="100" viewBox="0 0 137 137" fill="none">
                                        <path class="amin-path" d="M113 25L25 113V25L113 113"></path>
                                    </svg>
                                </i>

                                <div>
                                    <a class="d-flex anchor-none">
                                        <div class="">
                                            <h3>Digital Marketing</h3>
                                            <p class="mB30 font-l dlxss-mB20">A brand without a clear voice is just noise. We will tell stories around your vision and business goals, that will turn the audience into your customers.</p>
                                        </div>

                                    </a>

                                    <div class="dl-row">
                                        <div class="dllg-6 dlmd-12 dlsm-12 dlxs-12">
                                            <div class="focus-hover dl-card-service">
                                                <h5 class="font-body m0">Market Research</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>

                                        <div class="dllg-6 dlmd-12 dlsm-12 dlxs-12">
                                            <div class="focus-hover dl-card-service">
                                                <h5 class="font-body m0">Search Engine Optimization (SEO)</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="dl-row">
                                        <div class="dllg-6 dlmd-12 dlsm-12 dlxs-12">
                                            <div class="focus-hover dl-card-service">
                                                <h5 class="font-body m0">Social Media Marketing (SMM)</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>

                                        <div class="dllg-6 dlmd-12 dlsm-12 dlxs-12">
                                            <div class="focus-hover dl-card-service">
                                                <h5 class="font-body m0">Online Reputation Management (ORM)</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="dl-row">
                                        <div class="dllg-6 dlmd-12 dlsm-12 dlxs-12">
                                            <div class="focus-hover dl-card-service">
                                                <h5 class="font-body m0">PPC</h5>
                                                <span class="focus-border"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="dl-row">
                                        <div class="dlxs-12">
                                            <ScrollAnimation animateIn="fadeInUp">
                                                <img src={require('../assets/img/team/process/darklion-studio-process-market.jpg')} alt="DarkLion Studio Digital Marketing Services" class="dl-img-responsive dl-pos-in" />
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="dl-block dl-scroll-block dl-block-dark dl-aboutUs-slider dl-initiatives text-center border-bottom" id="values">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12">
                            <h3>Values, we provide to our clients</h3>
                            <p class="font-l">To build positive & long-lasting relationship with our clients</p>
                        </div>
                    </div>

                    <div class="dl-row">
                        <div class="dlxs-12 dlsm-4 dlmd-4 dllg-4">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-icon-card mT30 dlxs-m0 dlxss-pB10">
                                    <i class="dl-icon">
                                        <svg width="130" height="130" viewBox="0 0 137 137" fill="none">
                                            <path d="M103.094 120H61.156C62.5424 106.71 53.6464 97.158 49.0252 94.0434C31.0021 81.5838 29.2691 62.5492 30.6555 54.5894C36.4784 19.4269 63.8133 12.2514 76.7529 13.0589C112.522 15.2739 120.077 45.5911 119.384 60.4729L128.742 73.2782C130.683 77.4312 126.085 78.7002 123.543 78.8156H119.384V94.0434C119.384 105.118 108.524 103.734 103.094 101.657V120Z" stroke="white" strokeWidth="3" />
                                            <path d="M104.475 34.4019C105.962 49.036 108.014 85.4722 99.4478 75.0789C88.7401 62.0874 91.417 24.9946 84.7247 39.7781C78.0323 54.5616 81.8055 77.4993 89 77.4993C91.5 77.4993 99.4478 76.9599 99.4478 57.2491C99.4478 37.5382 101.953 26.1205 99.4478 34.4019C93.498 54.0711 94.9861 65.3134 84.7247 57.2491C74.4632 49.1847 78.0324 28.5796 70.0016 39.7781C61.9708 50.9767 60.1862 50.9761 61.5247 61.7283C62.8631 72.4805 69.1092 76.0638 77.5862 59.0404C86.0631 42.017 74.9096 67.1031 68.2171 52.3206C61.5247 37.5382 60.404 33 58 47C56.798 54 57.5094 52.7698 55.7248 57.2491C53.9402 61.7283 53.941 61.7283 49.9256 61.7283C45.9102 61.7283 48.141 52.7686 42.7871 59.0404C37.4332 65.3122 40.0354 63.9682 32.0794 63.9682C27 63.9682 7.83494 63.9682 0 63.9682" stroke="white" strokeWidth="3" />
                                        </svg>
                                    </i>
                                    <h4 class="mT20 dlxs-mT5">We’re listeners</h4>
                                    <p class="m0">We know you have a great idea that needs to be, heard carefully.</p>
                                </div>
                            </ScrollAnimation>
                        </div>


                        <div class="dlxs-12 dlsm-4 dlmd-4 dllg-4">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-icon-card mT30 dlxs-m0 dlxss-pB10">
                                    <i class="dl-icon">
                                        <svg width="130" height="130" viewBox="0 0 137 137" fill="none">
                                            <path d="M16.502 78.5213C32.0674 60.7728 74.7587 35.9249 121 78.5213M111.319 102.154C104.58 108.418 83.0729 119.58 50.9549 114.113M41.2739 110.981C37.7621 109.557 29.8275 105.799 26.1829 102.154M89.6788 43.2141L99.7415 26.8662M68.8931 40.082V20.4941M48.3922 43.2141L37.1795 26.8662" stroke="white" strokeWidth="3" />
                                            <circle cx="68.6092" cy="85.0701" r="14.4451" stroke="white" strokeWidth="3" />
                                        </svg>
                                    </i>
                                    <h4 class="mT20 dlxs-mT5">We’re transparent</h4>
                                    <p class="m0">Whatever we create for you, we know we are obliged to let you know everything.</p>
                                </div>
                            </ScrollAnimation>
                        </div>


                        <div class="dlxs-12 dlsm-4 dlmd-4 dllg-4">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-icon-card mT30 dlxs-m0 dlxss-pB10">
                                    <i class="dl-icon">
                                        <svg width="130" height="130" viewBox="0 0 137 137" fill="none">
                                            <path d="M88.1263 126V88.0395C91.6345 85.3894 99.0519 77.406 100.656 66.6731L107.672 50.7725C108.173 49.1162 108.474 45.3067 105.667 43.3191C103.83 41.4972 99.1521 39.8409 95.1427 47.7912L90.6321 55.2441M90.6321 55.2441C84.2839 56.5692 71.9885 62.5981 73.5922 76.1136M90.6321 55.2441V20.4618C90.6321 17.6794 87.9596 15.9897 86.6233 15.4926C83.4162 14.3001 79.9412 15.9895 78.6045 16.9833M53.5453 126V88.0395C50.3712 85.2236 44.023 77.4055 44.023 68.6602V30.8964C43.8559 28.9088 44.5242 24.9337 48.5336 24.9337C50.7055 24.4368 55.0493 25.3312 55.0493 32.884V47.7912V20.4618C55.2164 18.1429 56.6531 13.5053 61.0634 13.5053C62.9011 13.3396 66.6766 14.0022 67.0775 17.9773V47.7912V13.5053C67.7454 11.8491 69.9833 8.63623 73.5922 9.03374C75.263 9.03358 78.6045 9.92765 78.6045 13.5053V16.9833M78.6045 47.7912V16.9833" stroke="white" strokeWidth="3" />
                                        </svg>
                                    </i>
                                    <h4 class="mT20 dlxs-mT5">We’re accountable</h4>
                                    <p class="m0">We value our clients & make them feel that they’re in good hands and we’re liable for what we say.</p>
                                </div>
                            </ScrollAnimation>
                        </div>


                        <div class="dlxs-12 dlsm-4 dlmd-4 dlmd-offset-2 dllg-4 dllg-offset-2">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-icon-card dlxs-m0 dlxss-pB10">
                                    <i class="dl-icon">
                                        <svg width="130" height="130" viewBox="0 0 137 137" fill="none">
                                            <path d="M69.8943 107.693L40.0807 122.848L45.149 91.0535L21 67.5793L55.2856 61.9336L69.8943 33.7051L84.5029 61.9336L117 67.5793L93.1488 91.0535L97.919 122.848L69.8943 107.693Z" stroke="white" strokeWidth="3" />
                                            <path d="M34.8594 25.1699L43.1816 33.4356" stroke="white" strokeWidth="3" />
                                            <path d="M69.9533 20.0688L69.6569 7.15156" stroke="white" strokeWidth="3" />
                                            <path d="M96.502 33.4356L104.824 25.1699" stroke="white" strokeWidth="3" />
                                        </svg>
                                    </i>
                                    <h4 class="mT20 dlxs-mT5">Quality matters</h4>
                                    <p class="m0">We understand that the direction of an idea should be authentic & we have processes for this.</p>
                                </div>
                            </ScrollAnimation>
                        </div>


                        <div class="dlxs-12 dlsm-4 dlmd-4 dllg-4">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-icon-card dlxs-m0 dlxss-pB10">
                                    <i class="dl-icon">
                                        <svg width="130" height="130" viewBox="0 0 135 135" fill="none">
                                            <path d="M34.7541 75.0036C24.1444 67.5418 7.73883 48.5484 20.8799 31.454C27.1371 23.3139 45.1197 12.4875 66.992 34.303C71.4807 28.3336 84.5387 17.5344 100.861 22.0929C106.405 23.641 118 28.9539 118 48.5484M94.7403 47.3274L79.4379 62.5901M64.1355 77.8528L79.4379 62.5901M111.471 64.4216L86.171 89.6559M106.982 52.6184L78.4178 81.1088M113.511 77.8528L82.0903 109.192C79.0979 112.313 71.3174 117.088 64.1355 111.227L45.7726 92.912C43.4602 90.4699 40.3862 84.2021 46.5887 78.6668L61.2791 64.4216L79.4379 62.5901" stroke="white" strokeWidth="3" />
                                        </svg>
                                    </i>
                                    <h4 class="mT20 dlxs-mT5">We’re loyal</h4>
                                    <p class="m0">We maintain the trust of our clients and keep sensitive data confidential.</p>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
                <ClientCta ctaEnable={true} />
            </section>


            <section class="dl-block dl-scroll-block" id="models">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12 text-center mB20">
                            <h3>Project engagement models</h3>
                            <p class="font-l">Need a trustable and reliable solution to solve your complex business design problems? We have flexible project engagement models, which suit you and your business needs.</p>
                        </div>

                        <div class="dlxs-12">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-border-card focus-hover d-flex dlxss-flex-col dlxss-center dlxss-pT0">
                                    <i class="dl-icon mR20">
                                        <svg width="110" height="110" viewBox="0 0 137 137" fill="none">
                                            <path d="M43.6643 115.229L62.3885 96.4735C63.5005 95.5071 65.4989 93.5026 69.7945 91.3311M103.419 66.6173L85.1997 83.1051C78.8301 88.4523 73.6039 88.9892 69.7945 91.3311M103.419 66.6173C104.999 64.6368 107.183 59.2292 105.048 56.9621C102.913 54.695 100.753 53.2785 95.4203 57.5564L105.048 49.2383C108.159 46.5644 108.427 43.4449 106.678 40.7714C105.641 39.187 102.886 36.9393 99.5678 38.8406M103.419 66.6173L123 86.2311M99.5678 38.8406L88.4584 49.2383M99.5678 38.8406C101.642 37.1077 105.789 31.562 101.642 27.9973C100.506 26.4624 96.6051 25.4719 93.3466 27.9973M93.3466 27.9973L80.3115 40.1771M93.3466 27.9973C92.5072 24.4818 89.1694 18.4014 82.5334 22.204L80.3115 24.2585M71.1278 32.7507L80.3115 24.2585M80.3115 24.2585L74.0903 18.6395C72.4116 17.0056 67.8987 15.1638 63.2772 20.8676C62.2897 22.2044 60.7295 25.502 62.3885 27.9973M62.3885 27.9973L65.0547 30.8193C65.7954 32.1562 67.0938 34.3842 64.462 37.6521C63.4251 38.9396 60.2259 40.8609 56.9079 38.8406M62.3885 27.9973L56.9079 22.9468C54.0639 20.2137 50.4397 21.5109 49.0572 22.6497C46.5687 24.313 46.8849 27.997 47.2799 29.4825M56.9079 38.8406L47.2799 29.4825M56.9079 38.8406C57.352 41.6626 56.0188 44.1878 54.9821 45.079C52.7602 46.9892 49.6005 46.4654 48.1686 45.8217M47.2799 29.4825C45.6999 27.4029 40.7622 23.5409 36.9112 27.9973C35.5781 29.3836 33.7117 32.9584 36.9112 36.1667L40.0218 38.8345M48.1686 45.8217C49.008 47.5547 50.094 50.872 47.2799 53.3972C46.3293 54.3504 43.9342 55.7739 41.2066 54.8403M48.1686 45.8217L40.0218 38.8345M40.0218 54.2884L32.6156 47.6042C31.7762 46.7131 30.6898 43.7422 32.6156 41.2172C33.6525 39.6288 36.5855 36.9284 40.0218 38.8345M40.0218 54.2884C40.4213 54.5259 40.8173 54.7071 41.2066 54.8403M40.0218 54.2884C36.4666 54.437 34.8372 55.3282 33.356 58.299C31.8155 62.2204 33.9979 65.0107 35.2816 65.9157M41.2066 54.8403V60.3785L35.2816 65.9157M15 86.2311L35.2816 65.9157M69.7945 91.3311L95.4203 117" stroke="black" strokeWidth="3" />
                                        </svg>
                                    </i>

                                    <div class="dl-card-text">
                                        <h4>Long-term</h4>
                                        <p>Businesses with rapid growth and more extensive business goals need evolving processes and continuous iterations. We'll help you by allocating the fixed resources on fixed costs and accompany you at all phases of your project.</p>
                                    </div>

                                    <div class="focus-border"></div>
                                </div>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-border-card focus-hover d-flex dlxss-flex-col dlxss-center">
                                    <i class="dl-icon mR20">
                                        <svg width="110" height="110" viewBox="0 0 137 137" fill="none">
                                            <circle cx="66.5" cy="68.5" r="51" stroke="black" strokeWidth="3" />
                                            <path d="M87 46H49.2535V69H84.7465V92H47" stroke="black" strokeWidth="3" />
                                            <path d="M84.5 31.5L65 68.5V102.5" stroke="black" strokeWidth="3" />
                                        </svg>
                                    </i>

                                    <div class="dl-card-text">
                                        <h4>Fixed - scope, time, price</h4>
                                        <p>Businesses that require assistance with projects, fixed goals, fixed scope of work, fixed time, and fixed budget. Our team is here to help you and up for the challenges.</p>
                                    </div>

                                    <div class="focus-border"></div>
                                </div>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-border-card focus-hover d-flex dlxss-flex-col dlxss-center">
                                    <i class="dl-icon mR20">
                                        <svg width="110" height="110" viewBox="0 0 137 137" fill="none">
                                            <path d="M20.1626 112C18.7732 83.6555 26.5199 4.55764 34.5232 24.688C44.5273 49.8509 42.0262 121.695 48.2788 93.0611C54.5314 64.4274 51.0061 20 44.2844 20C41.9487 20 34.5232 21.0447 34.5232 59.2221C34.5232 97.3995 32.1827 119.514 34.5232 103.474C40.082 65.3775 38.6916 43.6026 48.2788 59.2221C57.866 74.8417 54.5313 114.751 62.0344 93.0611C69.5374 71.3709 71.2048 71.3721 69.9542 50.5464C68.7037 29.7208 62.8681 22.7804 54.9482 55.7525C47.0283 88.7247 57.4489 40.1362 63.7016 68.7678C69.9542 97.3995 70.1472 105.825 73.2473 79.0732C74.8185 65.5151 74.3513 68.7678 75.373 59.2221C76.3488 50.1049 76.5543 49.0517 77.6213 49.0517C80.8913 49.0517 77.1542 70.3573 85.0956 70.3573C88.0501 70.3573 88.4071 70.3573 95.8403 70.3573C100.586 70.3573 105.68 70.3573 113 70.3573M118.97 70.3573H122" stroke="black" strokeWidth="3" />
                                        </svg>
                                    </i>

                                    <div class="dl-card-text">
                                        <h4>Design workshops (5 Days)</h4>
                                        <p>We will help you to validate your ideas and solve your business's design challenges. Through prototyping and testing with customers within 5 days of the design sprint (define the problem, design solutions, decide the solution, prototype the solution & test with customers).</p>
                                    </div>

                                    <div class="focus-border"></div>
                                </div>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-border-card focus-hover d-flex dlxss-flex-col dlxss-center dlxss-border-none dlxss-pB0">
                                    <i class="dl-icon mR20">
                                        <svg width="110" height="110" viewBox="0 0 137 137" fill="none">
                                            <rect x="28.5" y="95.5" width="80" height="19" stroke="black" strokeWidth="3" />
                                            <rect x="28.5" y="24.5" width="80" height="71" stroke="black" strokeWidth="3" />
                                            <path d="M41 45H69M41 67H62.7" stroke="black" strokeWidth="3" />
                                            <path d="M79 53.8289L97.3942 73L135 42" stroke="black" strokeWidth="3" />
                                        </svg>
                                    </i>

                                    <div class="dl-card-text">
                                        <h4>For UX/UI audit & improvements</h4>
                                        <p>We'll help you improve your product by assessing the current state of the existing product by finding the problem areas. We'll provide the validated solutions that will assure the quality, improve the user experience, and meet the business goals.</p>
                                    </div>

                                    <div class="focus-border"></div>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>

            <FootCta />
        </Fragment>
    )
}

export default Services