import React from 'react'

function Row({children}) {
    return (
        <div className="dl-row">
            {children}
        </div>
    )
}

export default Row