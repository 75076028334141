import React, { Fragment } from 'react'
import ClientCta from '../components/ClientCta/ClientCta'
import FootCta from '../components/FootCta/FootCta'

import ScrollAnimation from 'react-animate-on-scroll';
import { MetaInfo } from '../components/Seo/MetaInfo';
function OurWork() {

    return (
        <Fragment>
            <MetaInfo page_name="work" />

            <section class="dl-page-banner dl-block dl-block-dark text-center">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12 dlsm-12 dllg-12">
                            <ScrollAnimation animateIn="fadeInUp">
                                <h1 class="dl-page-title font-body">DarkLion Studio Work</h1>
                                <h2 class="mT0 mB20 font-w-600 font-62">From ideas to reality</h2>
                                <p class="font-l dlxs-mB0">Our processes and user-centric design approach help us to transform and shape ideas into reality <span class="line-break">that users love and clients desire.</span></p>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>

            <section class="dl-block dl-cs" id="dl-work">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlsm-6 dlxs-12 dlxss-12">
                            <ScrollAnimation animateIn="fadeInUp" delay={100}>
                                <a class="dl-cs-card anchor-none mT30 dlsm-mT0 dlsm-mB30 hover-scale">
                                    <div class="hover-scale-image">
                                        <img src={require('../assets/img/case-studies/darklion-studio-cs-cover.jpg')} alt="DarkLion Studio" class="dl-img-responsive" />
                                    </div>
                                    <span class="dl-button primary round small light mT30">Coming soon</span>
                                    <p class="mT10">Darklion Studio</p>
                                    <h4>Designing a powerful visual brand identity and digital presence.</h4>
                                    <p class="font-m">Branding, website design, digital marketing</p>
                                </a>
                            </ScrollAnimation>
                        </div>

                        <div class="dlsm-6 dlxs-12 dlxss-12">
                            <ScrollAnimation animateIn="fadeInUp" delay={300}>
                                <a class="dl-cs-card anchor-none mT30 dlsm-mT0 dlsm-mB30 hover-scale" >
                                    <div class="hover-scale-image">
                                        <img src={require('../assets/img/case-studies/alphav-cs-cover.jpg')} alt="AlphaV" class="dl-img-responsive" />
                                    </div>
                                    <span class="dl-button primary round small light mT30">Coming soon</span>
                                    <p class="mT10">AlphaV</p>
                                    <h4>Brand identity design for an emerging cloud consulting company.</h4>
                                    <p class="font-m">Brand identity design</p>
                                </a>
                            </ScrollAnimation>
                        </div>
                    </div>

                    <div class="dl-row">
                        <div class="dlsm-12 dlxs-12 dlxss-12">
                            <ScrollAnimation animateIn="fadeInUp" delay={300}>
                                <a class="dl-cs-card anchor-none mT30 dlsm-mT0 dlsm-mB30 hover-scale">
                                    <div class="hover-scale-image">
                                        <img src={require('../assets/img/case-studies/nclouds-cs-cover.jpg')} alt="nClouds" class="dl-img-responsive" />
                                    </div>
                                    <span class="dl-button primary round small light mT30">Coming soon</span>
                                    <p class="mT10">nClouds</p>
                                    <h4>Brand identity design & website design work for an award-winning DevOps and cloud professional services provider.</h4>
                                    <p class="font-m">Branding identity design, website design</p>
                                </a>
                            </ScrollAnimation>
                        </div>
                    </div>

                    <div class="dl-row">
                        <div class="dlsm-6 dlxs-12 dlxss-12">
                            <ScrollAnimation animateIn="fadeInUp" delay={300}>
                                <a class="dl-cs-card anchor-none mT30 dlsm-mT0  hover-scale">
                                    <div class="hover-scale-image">
                                        <img src={require('../assets/img/case-studies/coming-soon-1-cs-cover.jpg')} alt="Coming soon" class="dl-img-responsive" />
                                    </div>
                                    <span class="dl-button primary round small light mT30">Coming soon</span>
                                    <h4 class="mT10">Branding, product design, digital marketing for an ...</h4>
                                    <p class="font-m">Branding, product design, digital marketing</p>
                                </a>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>

            <ClientCta />
            <section class="dl-block dl-testimonials">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12">
                            <div class="testimonial">
                                <blockquote class="dlxss-center m0 p0">
                                    <i class="dl-icon">
                                        <svg width="60" height="42" viewBox="0 0 60 42" fill="none">
                                            <path d="M4.28571 42H17.1429L25.7143 25.2V0H0V25.2H12.8571L4.28571 42ZM38.5714 42H51.4286L60 25.2V0H34.2857V25.2H47.1429L38.5714 42Z" fill="black" />
                                        </svg>
                                    </i>
                                    <div class="content dlxs-mT20 mB50 dlxss-mB20">
                                        We have worked with DarkLion Studio team extensively over the past five years for a variety of projects including wireframes, UI/UX, website, presentations and other graphic design. They have been uniformly excellent, very responsive, and highly creative. I can’t recommend them highly enough.
                                    </div>

                                    <ul class="ul-none d-flex dlxss-flex-col dlxss-p0 dlxss-center">
                                        <li class="dlxss-p0 pR30"><img src={require('../assets/img/clients/Sunil-Maulik.png')} alt="client" /></li>
                                        <li class="dlxss-p0">
                                            <h6>Sunil Maulik</h6>
                                            <p>Mentor &amp; Advisor at Stanford SPARQ</p>
                                        </li>
                                    </ul>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FootCta />
        </Fragment>
    )
}

export default OurWork