import React, { Fragment } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Container from '../components/Container/Container';
import Row from '../components/Row/Row';

//images
import AidImage1 from '../assets/img/aid/darklion-aid-008-2-1.png';
import AidImage2 from "../assets/img/aid/darklion-aid-007-2-1.png";
import AidImage3 from "../assets/img/aid/darklion-aid-001-1-2.png";
import AidImage4 from "../assets/img/aid/darklion-aid-005-1-1.png";
import AidImage5 from "../assets/img/initiatives/darklion-aid-initiative1.jpg";
import AidImage6 from "../assets/img/initiatives/darklion-aid-initiative2.jpg";
import AidImage7 from "../assets/img/aid/darklion-aid-004-1-2.png";
import AidImage8 from "../assets/img/aid/darklion-aid-014-1-2.png";
import AidImage9 from "../assets/img/aid/darklion-aid-012-1-1.png";
import AidImage10 from "../assets/img/aid/darklion-aid-011-2-1.png";
import AidImage11 from "../assets/img/aid/darklion-aid-010-1-1.png";
import AidImage12 from "../assets/img/aid/darklion-aid-009-1-2.png";
import AidImage13 from "../assets/img/aid/darklion-aid-015-1-2.png";
import AidImage14 from "../assets/img/aid/darklion-aid-016-1-2.png";
import AidImage15 from "../assets/img/aid/darklion-aid-003-1-2.png";
import AidImage16 from "../assets/img/aid/darklion-aid-018-2-1.png";
import AidImage17 from "../assets/img/aid/darklion-aid-017-1-1.png";
import AidImage18 from "../assets/img/aid/darklion-aid-002-1-1.png";
import AidImage19 from "../assets/img/aid/darklion-aid-019-1-1.png";
import { MetaInfo } from '../components/Seo/MetaInfo';


function DarklionAid() {

    return (
        <Fragment>
            <MetaInfo page_name="darklion_aid" />
            
            <section class="dl-page-banner dl-block dl-block-dark text-center dl-block-aid">
                <Container>
                    <Row>
                        <div class="dlxs-12 dlsm-12 dllg-12">
                            <ScrollAnimation animateIn="fadeInUp" duration={2} delay={100}>
                                <h1 class="dl-page-title font-body">Our Small initiative</h1>
                                <h2 class="mT0 mB20 font-w-600 font-62">DarkLion Aid.</h2>
                                <p class="font-l">Our small initiative DarkLion Aid - where we all believe in “paying it forward” and helping people in need. We are hoping to build a community where we all come together to impact their lives and bring a ray of hope into them.</p>
                            </ScrollAnimation>
                        </div>
                    </Row>
                </Container>
            </section>

            <section class="dl-block dl-block-aid-media dl-block-dark text-center">
                <div class="gallery">
                    <div class="gallery__item gallery__item--v-2">
                        <div class="embed embed--1-2">
                            <img alt="DarkLion Aid" src={AidImage1} />
                        </div>
                    </div>
                    <div class="gallery__item gallery__item--v-2">
                        <div class="embed embed--1-2">
                            <img alt="DarkLion Aid" src={AidImage2} />
                        </div>
                    </div>
                    <div class="gallery__item gallery__item--h-2">
                        <div class="embed embed--2-1">
                            <img alt="DarkLion Aid" src={AidImage3} />
                        </div>
                        <div class="_overlay">
                            <a href="https://youtu.be/oD8f73qGDT4" target="_blank" class="_link">
                                <svg xmlns='http://www.w3.org/2000/svg'
                                    stroke-width='10' height="32" stroke-dashoffset='0'
                                    stroke-dasharray='0' stroke-linecap='round'
                                    stroke-linejoin='round' viewBox='0 0 100 100'>
                                    <polyline fill="none" points="40 20 20 20 20 90 80 90 80 60" />
                                    <polyline fill="none" points="60 10 90 10 90 40" />
                                    <line fill="none" x1="89" y1="11" x2="50" y2="50" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div class="gallery__item">
                        <div class="embed">
                            <img alt="DarkLion Aid" src={AidImage4} />
                        </div>
                        <div class="_overlay">
                            <a href="https://www.youtube.com/watch?v=Fj-LEKcmpPs" target="_blank" class="_link">
                                <svg xmlns='http://www.w3.org/2000/svg'
                                    stroke-width='10' height="32" stroke-dashoffset='0'
                                    stroke-dasharray='0' stroke-linecap='round'
                                    stroke-linejoin='round' viewBox='0 0 100 100'>
                                    <polyline fill="none" points="40 20 20 20 20 90 80 90 80 60" />
                                    <polyline fill="none" points="60 10 90 10 90 40" />
                                    <line fill="none" x1="89" y1="11" x2="50" y2="50" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div class="gallery__item gallery__item--v-2">
                        <div class="embed embed--1-2">
                            <img alt="DarkLion Aid" src={AidImage5} />
                        </div>
                    </div>
                    <div class="gallery__item">
                        <div class="embed">
                            <img alt="DarkLion Aid" src={AidImage6} />
                        </div>
                    </div>
                    <div class="gallery__item gallery__item--h-2">
                        <div class="embed embed--2-1">
                            <img alt="DarkLion Aid" src={AidImage7} />
                        </div>
                    </div>
                    <div class="gallery__item gallery__item--h-2">
                        <div class="embed embed--2-1">
                            <img alt="DarkLion Aid" src={AidImage8} />
                        </div>
                        <div class="_overlay">
                            <a href="https://www.youtube.com/watch?v=xhfC8En3Ss0" target="_blank" class="_link">
                                <svg xmlns='http://www.w3.org/2000/svg'
                                    stroke-width='10' height="32" stroke-dashoffset='0'
                                    stroke-dasharray='0' stroke-linecap='round'
                                    stroke-linejoin='round' viewBox='0 0 100 100'>
                                    <polyline fill="none" points="40 20 20 20 20 90 80 90 80 60" />
                                    <polyline fill="none" points="60 10 90 10 90 40" />
                                    <line fill="none" x1="89" y1="11" x2="50" y2="50" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div class="gallery__item">
                        <div class="embed">
                            <img alt="DarkLion Aid" src={AidImage9} />
                        </div>
                    </div>
                    <div class="gallery__item gallery__item--v-2">
                        <div class="embed embed--1-2">
                            <img alt="DarkLion Aid" src={AidImage10} />
                        </div>
                    </div>
                    <div class="gallery__item">
                        <div class="embed">
                            <img alt="DarkLion Aid" src={AidImage11} />
                        </div>
                    </div>
                    <div class="gallery__item gallery__item--h-2">
                        <div class="embed embed--2-1">
                            <img alt="DarkLion Aid" src={AidImage12} />
                        </div>
                    </div>
                    <div class="gallery__item gallery__item--h-2">
                        <div class="embed embed--2-1">
                            <img alt="DarkLion Aid" src={AidImage13} />
                        </div>

                    </div>
                    <div class="gallery__item gallery__item--h-2">
                        <div class="embed embed--2-1">
                            <img alt="DarkLion Aid" src={AidImage14} />
                        </div>
                    </div>
                    <div class="gallery__item gallery__item--h-2">
                        <div class="embed embed--2-1">
                            <img alt="DarkLion Aid" src={AidImage15} />
                        </div>
                    </div>
                    <div class="gallery__item gallery__item--v-2">
                        <div class="embed embed--1-2">
                            <img alt="DarkLion Aid" src={AidImage16} />
                        </div>
                    </div>
                    <div class="gallery__item">
                        <div class="embed">
                            <img alt="DarkLion Aid" src={AidImage17} />
                        </div>
                        <div class="_overlay">
                            <a href="https://www.youtube.com/watch?v=xhfC8En3Ss0" target="_blank" class="_link">
                                <svg xmlns='http://www.w3.org/2000/svg'
                                    stroke-width='10' height="32" stroke-dashoffset='0'
                                    stroke-dasharray='0' stroke-linecap='round'
                                    stroke-linejoin='round' viewBox='0 0 100 100'>
                                    <polyline fill="none" points="40 20 20 20 20 90 80 90 80 60" />
                                    <polyline fill="none" points="60 10 90 10 90 40" />
                                    <line fill="none" x1="89" y1="11" x2="50" y2="50" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div class="gallery__item">
                        <div class="embed">
                            <img alt="DarkLion Aid" src={AidImage18} />
                        </div>
                    </div>
                    <div class="gallery__item">
                        <div class="embed">
                            <img alt="DarkLion Aid" src={AidImage19} />
                        </div>
                    </div>
                </div>

            </section>

            <section class="dl-block _event_announcement">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12 text-center p0">
                            <h2 data-title="How DarkLion Studio can help you in creating a future-proof brand identity">Every Month We Organize DarkLion Aid Day</h2>
                            <p class="font-l">
                                You can support & follow us on our social handles to stay up-to date.
                            </p>
                        </div>
                        <div class="dlxs-12 text-center mT40 flex-jcc _event_announcement_buttons">
                            <a href="https://www.instagram.com/darklionaid" target="_blank" title="Support us on Instagram" class="dl-button primary dl-hover dl-button-social-icon">
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="31" viewBox="0 0 31 31" fill="none">
                                        <path d="M16.1883 7.80859C11.8674 7.80859 8.28711 11.3271 8.28711 15.7098C8.28711 20.0925 11.8056 23.6111 16.1883 23.6111C20.5711 23.6111 24.0896 20.0308 24.0896 15.7098C24.0896 11.3888 20.5093 7.80859 16.1883 7.80859ZM16.1883 20.7716C13.4106 20.7716 11.1266 18.4876 11.1266 15.7098C11.1266 12.932 13.4106 10.6481 16.1883 10.6481C18.9661 10.6481 21.2501 12.932 21.2501 15.7098C21.2501 18.4876 18.9661 20.7716 16.1883 20.7716Z" fill="white" />
                                        <path d="M24.3985 9.41374C25.3872 9.41374 26.1886 8.61228 26.1886 7.62362C26.1886 6.63496 25.3872 5.8335 24.3985 5.8335C23.4099 5.8335 22.6084 6.63496 22.6084 7.62362C22.6084 8.61228 23.4099 9.41374 24.3985 9.41374Z" fill="white" />
                                        <path d="M29.028 2.99375C27.4231 1.32709 25.1391 0.462891 22.5465 0.462891H9.8305C4.46013 0.462891 0.879883 4.04314 0.879883 9.41351V22.0678C0.879883 24.7221 1.74408 27.0061 3.47248 28.6728C5.13914 30.2777 7.36136 31.0802 9.89223 31.0802H22.4848C25.1391 31.0802 27.3614 30.216 28.9663 28.6728C30.633 27.0678 31.4972 24.7839 31.4972 22.1296V9.41351C31.4972 6.82091 30.633 4.59869 29.028 2.99375ZM28.7811 22.1296C28.7811 24.0431 28.1021 25.5863 26.991 26.6357C25.8799 27.6851 24.3367 28.2407 22.4848 28.2407H9.89223C8.04038 28.2407 6.49717 27.6851 5.38606 26.6357C4.27494 25.5246 3.71939 23.9814 3.71939 22.0678V9.41351C3.71939 7.56166 4.27494 6.01845 5.38606 4.90733C6.43544 3.85795 8.04038 3.3024 9.89223 3.3024H22.6083C24.4601 3.3024 26.0033 3.85795 27.1145 4.96906C28.1638 6.08017 28.7811 7.62338 28.7811 9.41351V22.1296Z" fill="white" />
                                    </svg>
                                </i>
                                Support us on Instagram
                            </a>
                            <a href="https://www.linkedin.com/company/darklionaid/" target="_blank" title="Support us on Linkedin" class="dl-button primary dl-hover dl-button-social-icon">
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
                                        <path d="M5.57513 6.96924H0.78125V21.7472H5.57513V6.96924Z" fill="white" />
                                        <path d="M18.6329 7.14255C18.582 7.1266 18.5338 7.10917 18.4804 7.09412C18.4158 7.07943 18.3517 7.06717 18.2859 7.0567C18.0316 7.00554 17.7525 6.96928 17.4258 6.96928C14.6311 6.96928 12.8582 9.00748 12.2744 9.7946V6.96924H7.48047V21.7472H12.2744V13.6865C12.2744 13.6865 15.897 8.62708 17.4257 12.3429V21.7472H22.2183V11.7748C22.2183 9.54187 20.6921 7.68125 18.6329 7.14255Z" fill="white" />
                                        <path d="M5.47069 2.60343C5.47069 3.90207 4.42073 4.9545 3.12599 4.9545C1.83116 4.9545 0.78125 3.90203 0.78125 2.60343C0.78125 1.30505 1.83116 0.252441 3.12599 0.252441C4.42073 0.252441 5.47069 1.30505 5.47069 2.60343Z" fill="white" />
                                    </svg>
                                </i>
                                Support us on Linkedin
                            </a>
                        </div>
                        <p class="text-center font-s d-inline-block mT20 w-100">
                            If you'd like to join us and volunteer on the day of the event, or contribute in any way, please don't hesitate to DM us on our social handles. We kindly request that you refrain from contributing monetarily. Instead, you can make a difference by using Amazon or BlinkIt to deliver groceries or any other items which can be shared with needy before 3 days event takes place. Your contribution will make a huge impact and we appreciate your kindness and generosity.
                        </p>
                    </div>
                </div>
            </section>

        </Fragment>
    )
}

export default DarklionAid