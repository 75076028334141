import React from 'react'
import Block from '../Block/Block'
import Container from '../Container/Container'
import Row from '../Row/Row'
import { Link } from 'react-router-dom'

const ClientCta = ({ctaEnable = false}) => {


    return (
        <Block classProps={'dl-block-dark dl-clients dl-clients_grid'}>
            <Container>
                <Row>
                    <div className="dlxs-12 text-center">
                        <h3>Our valuable clients</h3>
                        <p className="font-l">Who trusted us and allowed us to work on their ideas &amp; build around their vision.</p>

                        <div className="dl-clients-slider">
                            <div className="dl-row dl-clients-items">
                                <div className='dl-clients-item animate fadeInUp'>
                                    <img src={require('../../assets/img/client-logos/nops01.png')} alt="Client nOps" />
                                </div>


                                <div className="dl-clients-item animate fadeInUp">
                                    <img src={require('../../assets/img/client-logos/xops01.png')} alt="Client nOps" />
                                </div>

                                <div className="dl-clients-item animate fadeInUp">
                                    <img src={require('../../assets/img/client-logos/nclouds01.png')} alt="Client nClouds" />
                                </div>

                                <div className="dl-clients-item animate fadeInUp">
                                    <img src={require('../../assets/img/client-logos/alphav01.png')} alt="Client Alphav" />
                                </div>

                                <div className="dl-clients-item animate fadeInUp">
                                    <img src={require('../../assets/img/client-logos/enforkcreations01.png')} alt="Client Enfork Creations" />
                                </div>
                            </div>
                            {ctaEnable ?
                                <div class="dlxs-12 text-center">
                                    <div class="dl-cta mT50 text-center">
                                        <Link target="_blank" to="/work" title="See Case Studies" class="dl-button white">
                                            See Case Studies
                                            <i>
                                                <svg width="20px" height="20px" viewBox="0 0 268.832 268.832">
                                                    <path d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,17.678l58.661,58.661H12.5
                                    c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,58.661c-4.882,4.882-4.882,12.796,0,17.678
                                    c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z"></path>
                                                </svg>
                                            </i>
                                        </Link>
                                    </div>
                                </div> : ''}
                        </div>
                    </div>
                </Row>
            </Container>
        </Block>
    )
}

export default ClientCta