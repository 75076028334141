import React, { Fragment } from 'react';
import Logo from "../../assets/img/dark-logo-brand-design.svg"
import { Link } from 'react-router-dom';
import styled from 'styled-components';


function Header({ mouseFunction, mouseOutFunction, modalClick }) {


    return (
        <Fragment>
            <HeaderWrapper onMouseEnter={mouseFunction} onMouseLeave={mouseOutFunction} className='nav-down'>
                <div className="dl-container-fluid">
                    <div className="dl-row">
                        <div className="dlxs-12">
                            <div className="d-flex flex-ac">
                                <div className="logo dl-logo cursor-scale">
                                    <Link to="https://darklion.studio/" aria-label="DarkLion Studio home page" title="DarkLion Studio home page">
                                        <img src={Logo} alt="DarkLion Studio Logo" className='logo' />
                                    </Link>
                                </div>

                                <nav className="flex-grow-1">
                                    <ul className="d-flex flex-ac _nav_items">
                                        <li className="cursor-scale">
                                            <button aria-label="Contact DarkLion Studio" title="Let’s Initiate" className="dl-button small" data-modal="toggle" onClick={modalClick}>Let’s Initiate</button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </HeaderWrapper>
        </Fragment>
    )
}

export default Header;


const HeaderWrapper = styled.header`
    padding-top: 17px;
    padding-bottom: 19px;

    &.nav-up{
        padding:0;
    }
    
    .logo {
        width: 340px;
        height: auto;
        object-fit: contain;
    }

    @media screen and (max-width:1024px){
        .dl-logo {
            width: 6.5rem;
        }

        &.scrolled .dl-logo {
            width: 6.5rem !important;
        }

        .logo {
            width: auto;
        }
    }

    @media screen and (max-width:768){
        .dl-logo {
            width: 6.5rem;
            overflow: hidden;
        }

        &.scrolled .dl-logo {
            width: 6.5rem !important;
        }

        .logo {
            height: 58px;
            width: auto;
        }
    }

    @media screen and (max-width:480px){
        .dl-logo {
            width: 6.5rem !important;
        }

        .logo {
            height: 58px;
            width: auto;
        }
    }
`;