import React from 'react'

function Container({ children }) {
    return (

        <div className='dl-container'>
            {children}
        </div>

    )
}

export default Container