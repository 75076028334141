import React, { Fragment, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Slider = ({ slides, options }) => {
    const swiperElRef = useRef(null);
return (
    <Fragment>
        <Swiper
            ref={swiperElRef}
            {...options}
            className="swiper mySwiper"
        >
            {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                    <img src={slide.image} alt={slide.alt} className="dl-img-responsive" />
                </SwiperSlide>
            ))}
        </Swiper>
    </Fragment>
);
}

export default Slider;