// Import images for grid items
import visionaryValueImg from './assets/img/visionary-value-300.gif';
import functionalValueImg from './assets/img/functional-value-300.gif';
import rationalValueImg from './assets/img/rational-value-300.gif';
import emotionalValueImg from './assets/img/emotional-value-300.gif';

// Import images for customer data
import listenersImg from './assets/img/preview/head.gif';
import transparentImg from './assets/img/preview/eye.gif';
import accountableImg from './assets/img/preview/hand.gif';
import qualityMattersImg from './assets/img/preview/star.gif';
import loyalImg from './assets/img/preview/heart.gif';

//Import images for brandpartner
import nOps from './assets/img/nOps.svg';
import xOps from './assets/img/xOps.svg';
import nClouds from './assets/img/nClouds.svg';
import alphav from './assets/img/alphav.svg';
import qcumber from './assets/img/qcumber.svg';
import renteaz from './assets/img/renteaz.svg';
import punjabiFitness from './assets/img/punjabi-fitness.svg';

const ourValueData = [
  {
    imgSrc: visionaryValueImg,
    imgAlt: 'visionary-value',
    heading: 'Visionary Value',
    text: 'We will stand by you through every stage of your brand\'s journey, from idea to brand to innovation. We aim to ensure that every aspect of the workpiece aligns seamlessly with your brand vision.',
  },
  {
    imgSrc: functionalValueImg,
    imgAlt: 'functional-value',
    heading: 'Functional Value',
    text: 'We know brands don\'t build in 1 day, but from day 1, we ensure that the right team is in place to strategize and execute the work aligned with your brand\'s current phase and build sustainable and scalable systems for the upcoming phases.',
  },
  {
    imgSrc: rationalValueImg,
    imgAlt: 'rational-value',
    heading: 'Rational Value',
    text: 'Our brand design team will be at the centre of your vision, business goals, and customer needs to reduce the gaps of un-alignments. Which directly saves your and your brand’s precious time, energy, money and impacts all the vital numbers required to sustain and grow. Further, our payment model is solely based on the value we provide.',
  },
  {
    imgSrc: emotionalValueImg,
    imgAlt: 'emotional-value',
    heading: 'Emotional Value',
    text: 'We will make every pixel of your brand express emotions aligned with your brand\'s core. This will help to create the brand personality that you desire, and will make both you and your team feel proud to be associated with it. Externally, through the art of storytelling, we will take your brand’s stories to the minds and hearts of your customers far beyond attraction and conversion.',
  }
];

const customerValueData = [
  {
    imgSrc: listenersImg,
    imgAlt: 'We’re listeners',
    heading: 'We’re listeners',
    text: 'We know, you have a great idea, that needs to be heard carefully.',
  },
  {
    imgSrc: transparentImg,
    imgAlt: 'We’re transparent',
    heading: 'We’re transparent',
    text: 'Whatever we create for you, we know, we are obliged to let you know everything',
  },
  {
    imgSrc: accountableImg,
    imgAlt: 'We’re accountable',
    heading: 'We’re accountable',
    text: 'We value our clients & make them feel that they’re in good hands, also, we’re liable for what we say.',
  },
  {
    imgSrc: qualityMattersImg,
    imgAlt: 'Quality matters',
    heading: 'Quality matters',
    text: 'We understand, direction of an idea, should be authentic & we’ve processes for this',
  },
  {
    imgSrc: loyalImg,
    imgAlt: 'We’re loyal',
    heading: 'We’re loyal',
    text: 'We maintain the trust of our clients and keep the sensitive data confidential.',
  }
];

const faqItems = [
    {
        title: 'What is the DarkLion Studio Brand Design Partner Program?',
        content: 'The DarkLion Studio Brand Design Partner Program is designed to guide brands and entrepreneurs through every phase of their business journey, from startup to innovation...',
    },
    {
        title: 'What is the payment model for DarkLion Studio\'s services?',
        content: 'At DarkLion Studio, our payment model is designed to align with the value we deliver to your brand...',
    },
    {
        title: 'Can I have a trial period before committing to a long-term partnership?',
        content: 'Yes, we offer a one-month trial period to determine if we are a good fit in terms of vision, creativity, culture, and actions...',
    },
    {
        title: 'What additional initiatives & brand does DarkLion Studio have?',
        content: 'Besides our core services, we have initiatives and brands like...',
    },
];

const brandPartnerLogos = [
    { src: nOps, alt: 'nOps' },
    { src: xOps, alt: 'xOps' },
    { src: nClouds, alt: 'nClouds' },
    { src: alphav, alt: 'alphav' },
    { src: qcumber, alt: 'qcumber' },
    { src: renteaz, alt: 'renteaz' },
    { src: punjabiFitness, alt: 'punjabi-fitness' },
];

export { ourValueData, customerValueData, faqItems, brandPartnerLogos };
