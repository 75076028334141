import React from 'react';

const GridItem = ({ imgSrc, imgAlt, heading, text }) => {
  return (
    <div className="_our_grid_item">
      <figure>
        <img src={imgSrc} alt={imgAlt} />
      </figure>
      <h6>{heading}</h6>
      <p>{text}</p>
    </div>
  );
};

export default GridItem;
