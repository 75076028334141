import React, { Fragment, useEffect, useState, useRef } from 'react'
import Container from '../components/Container/Container'
import Row from '../components/Row/Row'
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom'
import { MetaInfo } from '../components/Seo/MetaInfo';

function Process({ stickyNavbarTopPosition }) {
    const sections = [
        { id: 'discover', title: 'Discover' },
        { id: 'research', title: 'Research' },
        { id: 'design', title: 'Design' },
        { id: 'develop', title: 'Develop' },
        { id: 'optimize', title: 'Optimize' },
        { id: 'values', title: 'Our values' },
    ];

    const [activeId, setActiveId] = useState(sections[0].id);
    const handleScroll = () => {
        const scrollDistance = window.scrollY;

        let currentId = sections[0].id;
        sections.forEach((section) => {
            const element = document.getElementById(section.id);
            const offsetTop = element.offsetTop;
            const offsetHeight = element.offsetHeight;
            if (scrollDistance >= offsetTop - 1 && scrollDistance < offsetTop + offsetHeight - 1) {
                currentId = section.id;
            }
        });
        setActiveId(currentId);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Initial call to set the correct active link
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <Fragment>
            <MetaInfo page_name="process" />

            <section className="dl-page-banner dl-block dl-block-dark text-center">
                <Container>
                    <Row>
                        <div className="dlxs-12 dlsm-12 dllg-12">
                            <ScrollAnimation animateIn="fadeInUp">
                                <h1 className="dl-page-title font-body">DarkLion Studio Process</h1>
                                <h2 className="mT0 mB20 font-w-600 font-62">How we work for you?</h2>
                                <p className="font-l">We help businesses of any scale to design desirable brands, digital products on time and budget through our business and customer centric design process.</p>
                            </ScrollAnimation>
                        </div>
                    </Row>
                </Container>
            </section>
            <div className="sticky-sub-nav services border-bottom-grey sub-nav-up" style={{ top: stickyNavbarTopPosition ? '92px' : '' }}>
                <div className="dl-container">
                    <div className="dl-row">
                        <div className="dlxs-12 dlsm-12 dllg-12">
                            <nav>
                                <ul className="ul-none d-flex flex-jcc font-m m0">
                                    {/* <li><a href="#discover" className={`anchor-none ${activeSection === 'discover' ? 'active' : ''} `} onClick={() => scrollToSection('discover')}>1. Discover</a></li>
                                    <li><a href="#research" className={`anchor-none ${activeSection === 'research' ? 'active' : ''} `} onClick={() => scrollToSection('research')}>2. Research</a></li>
                                    <li><a href="#design" className={`anchor-none ${activeSection === 'design' ? 'active' : ''} `} onClick={() => scrollToSection('design')}>3. Design</a></li>
                                    <li><a href="#develop" className={`anchor-none ${activeSection === 'develop' ? 'active' : ''} `} onClick={() => scrollToSection('develop')}>4. Develop</a></li>
                                    <li><a href="#optimize" className={`anchor-none ${activeSection === 'optimize' ? 'active' : ''} `} onClick={() => scrollToSection('optimize')}>5. Optimize</a></li>
                                    <li><a href="#values" className={`anchor-none ${activeSection === 'values' ? 'active' : ''} `} onClick={() => scrollToSection('values')}>Our values</a></li> */}
                                    {sections.map((section, index) => (
                                        <li key={section.id}>
                                            <a href={`#${section.id}`} onClick={() => scrollToSection(section.id)} className={activeId === section.id ? 'active anchor-none' : 'anchor-none'}>
                                                {sections.length - 1 == index ? '' : index + 1 + '.'} {section.title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <section className="dl-block dl-scroll-block" id="discover">
                <div className="dl-container">
                    <div className="dl-row">
                        <div className="dlxs-12 dlsm-12 dllg-12 text-left">
                            <div className="dl-service-card d-flex dlxss-flex-col dlxss-center">
                                <i className="dl-icon mR20">
                                    <svg className="amin-icon dark" width="90" height="90" viewBox="0 0 137 137" fill="none">
                                        <path d="M96.543 96.041L120 119.498" stroke="black" strokeWidth="4"></path>
                                        <path d="M89.5254 88.5371L92.4575 91.4693" stroke="black" strokeWidth="4"></path>
                                        <circle className="amin-path" cx="68.5" cy="68.5" r="49.5" stroke="black" strokeWidth="4"></circle>
                                    </svg>
                                </i>

                                <div>
                                    <div className="d-flex">
                                        <div className="">
                                            <h3>1. Discover</h3>
                                            <p className="mB30 font-l dlxs-mB10">We begin by listening to your story. We start the project with a discovery workshop to understand your idea, vision, business goals, customer needs &amp; expectations. And we never stop asking questions until we get all the details, which will establish a strong foundation for our research phase and your product.</p>
                                        </div>
                                    </div>

                                    <div className="dl-row">
                                        <div className="dlxs-12">
                                            <div className="focus-hover dl-card-service">
                                                <h5 className="font-body dlsm-bold">What we do?</h5>
                                                <p className="font-l m0">Understand your business, Requirments elicitation, &amp; Estimation of scope</p>
                                                <span className="focus-border"></span>
                                            </div>
                                        </div>

                                        <div className="dlxs-12">
                                            <div className="focus-hover dl-card-service">
                                                <h5 className="font-body dlsm-bold">What we deliver?</h5>
                                                <p className="font-l m0">Scope document, Product roadmap, Team, Time estimation.</p>
                                                <span className="focus-border"></span>
                                            </div>
                                        </div>

                                        <div className="dlxs-12">
                                            <ScrollAnimation animateIn="fadeInUp">
                                                <img src={require('../assets/img/team/process/darklion-studio-discover.jpg')} alt="DarkLion Studio Process Discover" className="dl-img-responsive dl-pos-in  mT50 " />
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="dl-block dl-scroll-block dl-block-dark" id="research">
                <div className="dl-container">
                    <div className="dl-row">
                        <div className="dlxs-12 dlsm-12 dllg-12 text-left">
                            <div className="dl-service-card d-flex dlxss-flex-col dlxss-center">
                                <i className="dl-icon mR20">
                                    <svg className="amin-icon" width="90" height="90" viewBox="0 0 137 137" fill="none">
                                        <path className="amin-path" d="M121.5 123.5H15L55.5 64V13H78V64L121.5 123.5Z" stroke="white" strokeWidth="4"></path>
                                        <path d="M61 106H94.9411" stroke="white" strokeWidth="4"></path>
                                        <path d="M48 96H52.2426" stroke="white" strokeWidth="4"></path>
                                    </svg>
                                </i>

                                <div>
                                    <div className="d-flex">
                                        <div className="">
                                            <h3>2. Research</h3>
                                            <p className="mB30 font-l dlxs-mB10">After learning about your business. We go into the research phase to understand the market space, the users, and their needs. And, we prepare the blueprints &amp; strategies to start the artistic phase of shaping your dream.</p>
                                        </div>
                                    </div>

                                    <div className="dl-row">
                                        <div className="dlxs-12">
                                            <div className="focus-hover dl-card-service">
                                                <h5 className="font-body dlsm-bold">What we do?</h5>
                                                <p className="font-l m0">UX/UI Audit, UX research, Brand positioning, Competitive analysis</p>
                                                <span className="focus-border"></span>
                                            </div>
                                        </div>

                                        <div className="dlxs-12">
                                            <div className="focus-hover dl-card-service">
                                                <h5 className="font-body dlsm-bold">What we deliver?</h5>
                                                <p className="font-l m0"> Digital product design strategy, Brand strategy.</p>
                                                <span className="focus-border"></span>
                                            </div>
                                        </div>

                                        <div className="dlxs-12">
                                            <ScrollAnimation animateIn="fadeInUp">
                                                <img src={require('../assets/img/team/process/darklion-studio-research-strategy.jpg')} alt="DarkLion Studio Process Research and Strategy" className="dl-img-responsive dl-pos-in mT50" />
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="dl-block dl-scroll-block" id="design">
                <div className="dl-container">
                    <div className="dl-row">
                        <div className="dlxs-12 dlsm-12 dllg-12 text-left">
                            <div className="dl-service-card d-flex dlxss-flex-col dlxss-center">
                                <i className="dl-icon mR20">
                                    <svg className="amin-icon dark" width="90" height="90" viewBox="0 0 137 137" fill="none">
                                        <path d="M73.1797 60.7031H108.464" stroke="black" strokeWidth="4"></path>
                                        <path d="M113.723 60.7031H118.133" stroke="black" strokeWidth="4"></path>
                                        <rect x="20.0801" y="76.2168" width="42.7814" height="42.7814" stroke="black" strokeWidth="4"></rect>
                                        <path d="M22.9085 60.7814L62.8615 20.8284V60.7814H22.9085Z" stroke="black" strokeWidth="4"></path>
                                        <rect className="amin-path" x="74.1387" y="76.2168" width="42.7814" height="42.7814" rx="21.3907" stroke="black" strokeWidth="4"></rect>
                                    </svg>
                                </i>

                                <div>
                                    <div className="d-flex">
                                        <div className="">
                                            <h3>3. Design</h3>
                                            <p className="mB30 font-l dlxs-mB10">In this phase, your product comes to life - we signify it in the beauty with wisdom phase. Our cross-functional teams work collaboratively with our design team. We run into the loop of user experience design, user interface design, prototype, and test with stakeholders and the end customers until the design meets business requirements and user needs.</p>
                                        </div>
                                    </div>

                                    <div className="dl-row">
                                        <div className="dlxs-12">
                                            <div className="focus-hover dl-card-service">
                                                <h5 className="font-body dlsm-bold">What we do?</h5>
                                                <p className="font-l m0">UX design, Creating moodboards, Preparing design system, UI design prototyping, and testing</p>
                                                <span className="focus-border"></span>
                                            </div>
                                        </div>

                                        <div className="dlxs-12">
                                            <div className="focus-hover dl-card-service">
                                                <h5 className="font-body dlsm-bold">What we deliver?</h5>
                                                <p className="font-l m0">Design system, Functional designs, Branding assets, and Guidelines.</p>
                                                <span className="focus-border"></span>
                                            </div>
                                        </div>

                                        <div className="dlxs-12">
                                            <ScrollAnimation animateIn="fadeInUp">
                                                <img src={require('../assets/img/team/process/darklion-studio-process-design.jpg')} alt="DarkLion Studio Process Design" className="dl-img-responsive dl-pos-in mT50" />
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="dl-block dl-scroll-block dl-block-dark" id="develop">
                <div className="dl-container">
                    <div className="dl-row">
                        <div className="dlxs-12 dlsm-12 dllg-12 text-left">
                            <div className="dl-service-card d-flex dlxss-flex-col dlxss-center">
                                <i className="dl-icon mR20">
                                    <svg className="amin-icon" width="90" height="90" viewBox="0 0 137 137" fill="none">
                                        <path d="M79.7773 17.9824L82.6411 21.0025" stroke="white" strokeWidth="4"></path>
                                        <path d="M80.7559 119.125L129.283 69.3117L87.1319 25.8308" stroke="white" strokeWidth="4"></path>
                                        <path className="amin-path" d="M55.9121 18.0762L7.28728 67.7938L55.7131 119.194" stroke="white" strokeWidth="4"></path>
                                    </svg>
                                </i>

                                <div>
                                    <div className="d-flex">
                                        <div className="">
                                            <h3>4. Develop</h3>
                                            <p className="mB30 font-l dlxs-mB10">After testing and getting the nod on final designs from you, our development team start the turning your products visuals into functional product. Our QA team make sure that fully tested, working &amp; optimized product goes into production and infront of your audience.</p>
                                        </div>
                                    </div>

                                    <div className="dl-row">
                                        <div className="dlxs-12">
                                            <div className="focus-hover focus-hover-dark dl-card-service">
                                                <h5 className="font-body dlsm-bold">What we do?</h5>
                                                <p className="font-l m0">Environment setup, Front-end development, Back-end development, Quality assurance, CMS</p>
                                                <span className="focus-border"></span>
                                            </div>
                                        </div>

                                        <div className="dlxs-12">
                                            <div className="focus-hover focus-hover-dark dl-card-service">
                                                <h5 className="font-body dlsm-bold">What we deliver?</h5>
                                                <p className="font-l m0">Final product, Production deployment, Product documentation, 24/5 support.</p>
                                                <span className="focus-border"></span>
                                            </div>
                                        </div>

                                        <div className="dlxs-12">
                                            <ScrollAnimation animateIn="fadeInUp">
                                                <img src={require('../assets/img/team/process/darklion-studio-process-develop2.jpg')} alt="DarkLion Studio Process Develop" className="dl-img-responsive dl-pos-in mT50" />
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="dl-block dl-scroll-block" id="optimize">
                <div className="dl-container">
                    <div className="dl-row">
                        <div className="dlxs-12 dlsm-12 dllg-12 text-left">
                            <div className="dl-service-card d-flex dlxss-flex-col dlxss-center">
                                <i className="dl-icon mR20">
                                    <svg className="amin-icon dark" width="90" height="90" viewBox="0 0 137 137" fill="none">
                                        <path d="M69 128V71" stroke="black" strokeWidth="4"></path>
                                        <path d="M69 65.2422V60.9995" stroke="black" strokeWidth="4"></path>
                                        <circle className="amin-path" cx="68.5" cy="61.5" r="50.5" stroke="black" strokeWidth="4"></circle>
                                        <circle cx="68.5" cy="61.5" r="21.5" stroke="black" strokeWidth="4"></circle>
                                    </svg>
                                </i>

                                <div>
                                    <div className="d-flex">
                                        <div className="">
                                            <h3>5. Optimize</h3>
                                            <p className="mB30 font-l dlxs-mB10">Developing the product and releasing the MVP or first version of your product is just the starting stage. From there, you measure, learn, design and, develop ideas in an endless loop until you accomplish your business goals. We are with you till the end.</p>
                                        </div>
                                    </div>

                                    <div className="dl-row">
                                        <div className="dlxs-12">
                                            <div className="focus-hover focus-hover-dark dl-card-service">
                                                <h5 className="font-body dlsm-bold">What we do?</h5>
                                                <p className="font-l m0">Analytics tool setup, Data analysis, Hypothesis analysis, KPIs monitoring</p>
                                                <span className="focus-border"></span>
                                            </div>
                                        </div>

                                        <div className="dlxs-12">
                                            <div className="focus-hover focus-hover-dark dl-card-service">
                                                <h5 className="font-body dlsm-bold">What we deliver?</h5>
                                                <p className="font-l m0">We measure, we learn, we bring more ideas and we build again.</p>
                                                <span className="focus-border"></span>
                                            </div>
                                        </div>

                                        <div className="dlxs-12">
                                            <ScrollAnimation animateIn="fadeInUp">
                                                <img src={require('../assets/img/team/process/darklion-studio-process-optimize.jpg')} alt="DarkLion Studio Process Optimize" className="dl-img-responsive dl-pos-in mT50" />
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="dl-block dl-scroll-block dl-block-dark dl-aboutUs-slider dl-initiatives text-center border-bottom" id="values">
                <div className="dl-container">
                    <div className="dl-row">
                        <div className="dlxs-12">
                            <h3>How we collaborate</h3>
                            <p className="font-l">Keeping track of the results, performance, efficiency, and teamwork is a key to growth.So we take our customer values seriously.</p>
                        </div>

                        <div className="dlxs-12 dlsm-12 dllg-12">
                            <div className="d-flex flex-wrap">

                                <ScrollAnimation animateIn='fadeInUp' className="dl-icon-card flex-grid mT30 dlsm-mT0">
                                    <i className="dl-icon">
                                        <svg width="130" height="130" viewBox="0 0 137 137" fill="none">
                                            <path d="M93.1255 51.9785H125.569V102.59H81.8785L73.2269 116L61.1147 102.59H45.1094V91.3431" stroke="white" strokeWidth="3" />
                                            <path d="M80.146 51.5447V39H10.9336V83.5555H19.5851L28.6693 92.6396L36.4557 83.5555H107.398" stroke="white" strokeWidth="3" />
                                            <path d="M31.6992 54.5723H52.8543" stroke="white" strokeWidth="3" />
                                            <path d="M31.6992 64.0898H71.4963" stroke="white" strokeWidth="3" />
                                            <path d="M32 73H93" stroke="white" strokeWidth="3" />
                                            <path d="M85.8438 9L89.3713 20.1865" stroke="white" strokeWidth="3" />
                                            <path d="M107.054 20.7744L112.795 9.19914" stroke="white" strokeWidth="3" />
                                            <path d="M115.737 35.8852L126.947 32.4341" stroke="white" strokeWidth="3" />
                                        </svg>
                                    </i>
                                    <h4 className="mT20 dlxs-mT5">Communication</h4>
                                    <p>Aiming for perfection is our core value. We’re always open to giving ideas where we see room for improvement.</p>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn='fadeInUp' className="dl-icon-card flex-grid mT30 dlsm-mT0">
                                    <i className="dl-icon">
                                        <svg width="130" height="130" viewBox="0 0 137 137" fill="none">
                                            <path d="M20 107.896L38.9827 113.547L60.8859 103.271L82.3509 105.07C84.0058 105.198 87.1988 106.38 86.7316 110.079C86.4882 111.235 85.0085 113.547 81.0368 113.547L67.0187 112.263C65.9966 112.091 63.8939 112.263 63.6603 114.318C63.4656 114.96 63.6895 116.296 66.1426 116.501L90.3821 118.942L111.409 109.18C112.821 108.666 115.877 108.307 116.812 110.978C117.299 111.877 117.425 114.061 114.037 115.602L94.0326 127.162L58.9876 132.814L20 124.979" stroke="white" strokeWidth="3" />
                                            <path d="M90.6914 111.713L102.257 106.79C103.474 106.347 106.031 105.965 106.518 107.988" stroke="white" strokeWidth="3" />
                                            <path d="M107.738 53.2676L117.064 53.2676" stroke="white" strokeWidth="3" />
                                            <path d="M107.551 24.6445L100.729 31.4665" stroke="white" strokeWidth="3" />
                                            <path d="M39.625 24.6445L46.447 31.4665" stroke="white" strokeWidth="3" />
                                            <path d="M68.3047 93.7754L78.4787 93.7754" stroke="white" strokeWidth="3" />
                                            <path d="M73.3945 9.36914V18.6953" stroke="white" strokeWidth="3" />
                                            <path d="M29.7227 53.2676L39.0488 53.2676" stroke="white" strokeWidth="3" />
                                            <circle cx="73.2796" cy="53.1546" r="25.4046" stroke="white" strokeWidth="3" />
                                            <path d="M63.2617 86.3903V76.8945C71.8972 79.345 80.2242 77.9156 83.3083 76.8945V86.3903H63.2617Z" stroke="white" strokeWidth="3" />
                                        </svg>
                                    </i>
                                    <h4 className="mT20 dlxs-mT5">Share ideas</h4>
                                    <p>To discover your ideas and solve complex business problems, we collaborate and conduct workshops to solve problems together.</p>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn='fadeInUp' className="dl-icon-card flex-grid mT30 dlsm-mT0">
                                    <i className="dl-icon">
                                        <svg width="130" height="130" viewBox="0 0 137 137" fill="none">
                                            <path d="M105.76 44.6109C105.76 50.9178 100.417 56.134 93.6946 56.134C86.9726 56.134 81.6289 50.9178 81.6289 44.6109C81.6289 38.3041 86.9726 33.0879 93.6946 33.0879C100.417 33.0879 105.76 38.3041 105.76 44.6109Z" stroke="white" strokeWidth="3" />
                                            <path d="M95.8477 56.128C103.866 55.7081 120.156 62.3159 119.989 83.253V98.3223" stroke="white" strokeWidth="3" />
                                            <path d="M83.7885 92.3253L85.7982 83.2526C84.2191 53.1447 62.2071 47.4206 51.4961 47.988C26.1827 47.988 18.2712 69.5077 17.1836 82.3915V100.506" stroke="white" strokeWidth="3" />
                                            <path d="M67.8052 32.3486C67.8052 40.9031 60.5635 47.9413 51.5002 47.9413C42.437 47.9413 35.1953 40.9031 35.1953 32.3486C35.1953 23.7941 42.437 16.7559 51.5002 16.7559C60.5635 16.7559 67.8052 23.7941 67.8052 32.3486Z" stroke="white" strokeWidth="3" />
                                            <path d="M47.9346 104.123C47.9346 109.51 43.3494 113.989 37.5513 113.989C31.7532 113.989 27.168 109.51 27.168 104.123C27.168 98.7355 31.7532 94.2559 37.5513 94.2559C43.3494 94.2559 47.9346 98.7355 47.9346 104.123Z" stroke="white" strokeWidth="3" />
                                            <path d="M103.168 113.423L111.611 101.023L119.701 113.423H103.168Z" stroke="white" strokeWidth="3" />
                                            <rect x="58.168" y="104.246" width="34.8889" height="17.6667" stroke="white" strokeWidth="3" />
                                            <path d="M5 84.1445H129" stroke="white" strokeWidth="3" />
                                        </svg>
                                    </i>
                                    <h4 className="mT20 dlxs-mT5">Workshops</h4>
                                    <p>To discover your ideas and solve complex business problems, we collaborate and conduct workshops to solve problems together.</p>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn='fadeInUp' className="dl-icon-card flex-grid mT30 dlsm-mT0">
                                    <i className="dl-icon">
                                        <svg width="130" height="130" viewBox="0 0 137 137" fill="none">
                                            <circle cx="68.5" cy="68.5" r="51" stroke="white" strokeWidth="3" />
                                            <path d="M89 46H51.2535V69H86.7465V92H49" stroke="white" strokeWidth="3" />
                                            <path d="M86.5 31.5L67 68.5V102.5" stroke="white" strokeWidth="3" />
                                        </svg>
                                    </i>
                                    <h4 className="mT20 dlxs-mT5">Estimations</h4>
                                    <p>We value your time and money. We always aim to deliver projects on time and within budget by providing fair estimations and sticking to those.</p>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn='fadeInUp' className="dl-icon-card flex-grid mT30 dlsm-mT0">
                                    <i className="dl-icon">
                                        <svg width="130" height="130" viewBox="0 0 135 135" fill="none">
                                            <path d="M72.2344 34.1094H102.352" stroke="white" strokeWidth="3" />
                                            <path d="M72.2344 50.7598H119.005" stroke="white" strokeWidth="3" />
                                            <path d="M72.2344 85.1289H102.352" stroke="white" strokeWidth="3" />
                                            <path d="M72.2344 101.783H119.005" stroke="white" strokeWidth="3" />
                                            <path d="M25 41.1053L34.1971 51L53 35" stroke="white" strokeWidth="3" />
                                            <rect x="18.457" y="22.5" width="40.582" height="40.582" stroke="white" strokeWidth="3" />
                                            <rect x="18.457" y="75.6504" width="40.582" height="40.582" stroke="white" strokeWidth="3" />
                                        </svg>
                                    </i>
                                    <h4 className="mT20 dlxs-mT5">Reporting</h4>
                                    <p>We're transparent and disciplined. We send daily updates and weekly status reports about what we have accomplished and what are, we working on.</p>
                                </ScrollAnimation>

                                <ScrollAnimation animateIn='fadeInUp' className="dl-icon-card flex-grid mT30 dlsm-mT0">
                                    <i className="dl-icon">
                                        <svg width="130" height="130" viewBox="0 0 135 135" fill="none">
                                            <path d="M96.7767 27.9576L96.795 27.9752L96.8137 27.9921L104.502 34.9214V103.08H42.6328V20.1934H88.7249L96.7767 27.9576Z" stroke="white" strokeWidth="3" />
                                            <path d="M88 20.5V36H104" stroke="white" strokeWidth="3" />
                                            <path d="M53.9609 49.209H78.4133" stroke="white" strokeWidth="3" />
                                            <path d="M54.0625 59.918H92.2568" stroke="white" strokeWidth="3" />
                                            <path d="M54.0625 70.6289H92.0547" stroke="white" strokeWidth="3" />
                                            <path d="M54.0625 81.3398H92.0547" stroke="white" strokeWidth="3" />
                                            <path d="M28.5 31.1211V116.806H95.1884" stroke="white" strokeWidth="3" />
                                        </svg>
                                    </i>
                                    <h4 className="mT20 dlxs-mT5">Documentation</h4>
                                    <p>We document and organize what we do, as it is crucial for the scalability and sustainability of your business. So you always know where you are.</p>
                                </ScrollAnimation>
                            </div>
                        </div>

                        <div className="dlxs-12 text-center dl-cta">
                            <Link to="/services" title="View Our Services" className="dl-button white mT50">
                                View Our Services
                                <i>
                                    <svg width="20px" height="20px" viewBox="0 0 268.832 268.832">
                                        <path d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,17.678l58.661,58.661H12.5
                                c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,58.661c-4.882,4.882-4.882,12.796,0,17.678
                                c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z"></path>
                                    </svg>
                                </i>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="dl-block dl-cs" id="dl-work">
                <div className="dl-container">
                    <div className="dl-row">
                        <div className="dlxs-12 dlxss-12">
                            <div className="text-center">
                                <h2>Our recent work</h2>
                                <p className="font-l">Here are some of the case studies of our valuable clients</p>
                            </div>
                        </div>

                        <div className="dlmd-6 dlsm-12 dlxs-12 dlxss-12">
                            <a className="dl-cs-card anchor-none mT30 dlsm-mT0 dlsm-mB30 hover-scale">
                                <div className="hover-scale-image">
                                    <img src={require('../assets/img/case-studies/coming-soon-1-cs-cover.jpg')} alt="Coming soon" className="dl-img-responsive" />
                                </div>
                                <span className="dl-button primary round small light mT30">Coming soon</span>
                                <h4 className="mT10">Branding, product design, digital marketing for an ...</h4>
                                <p className="font-m">Branding, product design, digital marketing</p>
                            </a>
                        </div>

                        <div className="dlmd-6 dlsm-12 dlxs-12 dlxss-12">
                            <a className="dl-cs-card anchor-none mT30 dlsm-mT0 dlsm-mB30 hover-scale dlxs-mB0">
                                <div className="hover-scale-image">
                                    <img src={require('../assets/img/case-studies/alphav-cs-cover.jpg')} alt="AlphaV" className="dl-img-responsive" />
                                </div>
                                <span className="dl-button primary round small light mT30">Coming soon</span>
                                <p className="mT30 dlxs-mT10">AlphaV</p>
                                <h4>Brand identity design for an emerging cloud consulting company.</h4>
                                <p className="font-m dlxs-mB0">Brand identity design</p>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="dl-block dl-block-dark text-center dl-footCTA border-b-grey">
                <div className="dl-container">
                    <div className="dl-row">
                        <div className="dlxs-12">
                            <h2>Ready to transform your ideas into reality? Our team is here to help you.</h2>

                            <a href="/contact" title="Contact us" className="dl-button white mT40" aria-label="Contact us">
                                Contact us
                                <i>
                                    <svg width="20px" height="20px" viewBox="0 0 268.832 268.832">
                                        <path d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,17.678l58.661,58.661H12.5
                                    c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,58.661c-4.882,4.882-4.882,12.796,0,17.678
                                    c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z"></path>
                                    </svg>
                                </i>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Process