import React from 'react';
import PropTypes from 'prop-types'; 


const SectionInfo = ({ title, content, className, paragraphClass }) => {
  return (
    <div className={`section-info ${className}`}>
      <h2>{title}</h2>
      <p className={paragraphClass}>{content}</p>
    </div>
  );
};

SectionInfo.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
  paragraphClass: PropTypes.string,
};


SectionInfo.defaultProps = {
  className: '',
  paragraphClass: '',
};

export default SectionInfo;
