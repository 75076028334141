import React, { useState, useEffect } from 'react';

const TextTransition = ({ texts, interval }) => {
  const [index, setIndex] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setVisible(false); // Fade out the current text
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setVisible(true); 
      }, 700); 
    }, interval);

    return () => clearInterval(intervalId);
  }, [texts, interval]);

  return (
 
      <span className={`transition-item ${visible ? 'visible' : ''}`}>
        {texts[index]}
      </span>

  );
};

export default TextTransition;
