import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Block from '../components/Block/Block'
import Container from '../components/Container/Container'
import Row from '../components/Row/Row'

import Roar from '../assets/img/roar.mp4';
import FootCta from '../components/FootCta/FootCta';
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from '../components/Slider/Slider.jsx';
import Inspiration from '../assets/img/team/inspiration-of-darklion-studio.jpg';
import TeamGroup from '../assets/img/team/culture/darklion-studio-team-group1.jpg';
import StudioOffice from '../assets/img/team/culture/darklion-studio-office-1.jpg';
import TeamWork1 from '../assets/img/team/culture/darklion-studio-team-work1.jpg';
import TeamWork2 from '../assets/img/team/culture/darklion-studio-team-work2.jpg';
import TeamWork3 from '../assets/img/team/culture/darklion-studio-team-work3.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { MetaInfo } from '../components/Seo/MetaInfo.jsx'

function HomePage() {
    const swiperElRef = useRef(null);
    const [data, setData] = useState(null)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://edu.darklion.studio/wp-json/my/v1/latest-posts');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                setData(jsonData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const slides = [
        { image: Inspiration, alt: "DarkLion Studio Inspiration" },
        { image: TeamGroup, alt: "DarkLion Studio Team" },
        { image: StudioOffice, alt: "DarkLion Studio Team" },
        { image: TeamWork1, alt: "DarkLion Studio Team" },
        { image: TeamWork2, alt: "DarkLion Studio Team" },
        { image: TeamWork3, alt: "DarkLion Studio Team" }
    ]

    const options = {
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 30,
        navigation: true,
        autoplay: true,
        pagination: true,
        breakpoints: {
            576: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 1.5,
            },
        },
    };

    return (
        <Fragment>
            <MetaInfo page_name="home" />

            <Block classProps={'dl-roar'}>
                <div className="homeImage">
                    <video loop={true} autoPlay={true} muted={true}>
                        <source src={Roar} type="video/mp4" />
                    </video>
                </div>
                <div className="dl-container">
                    <div className="dl-row">
                        <div className="dlmd-7 dlsm-12 dlxs-12 text-left dlxs-center">
                            <h1 className="font-body font-n font-p">The Brand Design Studio</h1>
                            <h2 className="mB40 h1-l dlxs-mB20">From Ideas to Brands to Innovation - we are here with you at every stage of your business.</h2>
                        </div>

                        <div className="dlxs-12 dl-cta dlxs-center">
                            <Link to="/contact" title="Contact Us" className="dl-button">
                                Contact Us
                                <i>
                                    <svg width="20px" height="20px" viewBox="0 0 268.832 268.832">
                                        <path d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,17.678l58.661,58.661H12.5
                                    c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,58.661c-4.882,4.882-4.882,12.796,0,17.678
                                    c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z"></path>
                                    </svg>
                                </i>
                            </Link>
                        </div>

                        <ul className="dl-social d-flex dlsm-jcc">
                            <li>
                                <a href="https://www.linkedin.com/company/darklionstudio" target="_blank" title="Connect with us on LinkedIn" aria-label="Connect with us on LinkedIn">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M20 40C31.0457 40 40 31.0459 40 20C40 8.9541 31.0457 0 20 0C8.95432 0 0 8.9541 0 20C0 31.0459 8.95432 40 20 40ZM9.78149 15.4697H14.5754V30.248H9.78149V15.4697ZM27.6331 15.6426L27.5761 15.625C27.545 15.6143 27.5138 15.6035 27.4805 15.5947L27.3589 15.5693L27.2861 15.5566C27.0318 15.5059 26.7527 15.4697 26.426 15.4697C23.6313 15.4697 21.8584 17.5078 21.2746 18.2949V15.4697H16.4807V30.248H21.2745V22.1865C21.2745 22.1865 24.8972 17.1279 26.4259 20.8428V30.248H31.2185V20.2754C31.2185 18.042 29.6923 16.1816 27.6331 15.6426ZM12.1265 13.4541C13.4212 13.4541 14.4712 12.4023 14.4712 11.1035C14.4712 9.80469 13.4212 8.75195 12.1265 8.75195C10.8316 8.75195 9.78174 9.80469 9.78174 11.1035C9.78174 12.4023 10.8316 13.4541 12.1265 13.4541Z"></path>
                                    </svg>
                                </a>
                            </li>

                            <li>
                                <a href="https://www.instagram.com/darklionstudio" target="_blank" title="Connect with us on Instagram" aria-label="Connect with us on Instagram">
                                    <svg height="40px" viewBox="0 0 512 512" width="40px">
                                        <path d="m305 256c0 27.0625-21.9375 49-49 49s-49-21.9375-49-49 21.9375-49 49-49 49 21.9375 49 49zm0 0"></path>
                                        <path d="m370.59375 169.304688c-2.355469-6.382813-6.113281-12.160157-10.996094-16.902344-4.742187-4.882813-10.515625-8.640625-16.902344-10.996094-5.179687-2.011719-12.960937-4.40625-27.292968-5.058594-15.503906-.707031-20.152344-.859375-59.402344-.859375-39.253906 0-43.902344.148438-59.402344.855469-14.332031.65625-22.117187 3.050781-27.292968 5.0625-6.386719 2.355469-12.164063 6.113281-16.902344 10.996094-4.882813 4.742187-8.640625 10.515625-11 16.902344-2.011719 5.179687-4.40625 12.964843-5.058594 27.296874-.707031 15.5-.859375 20.148438-.859375 59.402344 0 39.25.152344 43.898438.859375 59.402344.652344 14.332031 3.046875 22.113281 5.058594 27.292969 2.359375 6.386719 6.113281 12.160156 10.996094 16.902343 4.742187 4.882813 10.515624 8.640626 16.902343 10.996094 5.179688 2.015625 12.964844 4.410156 27.296875 5.0625 15.5.707032 20.144532.855469 59.398438.855469 39.257812 0 43.90625-.148437 59.402344-.855469 14.332031-.652344 22.117187-3.046875 27.296874-5.0625 12.820313-4.945312 22.953126-15.078125 27.898438-27.898437 2.011719-5.179688 4.40625-12.960938 5.0625-27.292969.707031-15.503906.855469-20.152344.855469-59.402344 0-39.253906-.148438-43.902344-.855469-59.402344-.652344-14.332031-3.046875-22.117187-5.0625-27.296874zm-114.59375 162.179687c-41.691406 0-75.488281-33.792969-75.488281-75.484375s33.796875-75.484375 75.488281-75.484375c41.6875 0 75.484375 33.792969 75.484375 75.484375s-33.796875 75.484375-75.484375 75.484375zm78.46875-136.3125c-9.742188 0-17.640625-7.898437-17.640625-17.640625s7.898437-17.640625 17.640625-17.640625 17.640625 7.898437 17.640625 17.640625c-.003906 9.742188-7.898437 17.640625-17.640625 17.640625zm0 0"></path>
                                        <path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm146.113281 316.605469c-.710937 15.648437-3.199219 26.332031-6.832031 35.683593-7.636719 19.746094-23.246094 35.355469-42.992188 42.992188-9.347656 3.632812-20.035156 6.117188-35.679687 6.832031-15.675781.714844-20.683594.886719-60.605469.886719-39.925781 0-44.929687-.171875-60.609375-.886719-15.644531-.714843-26.332031-3.199219-35.679687-6.832031-9.8125-3.691406-18.695313-9.476562-26.039063-16.957031-7.476562-7.339844-13.261719-16.226563-16.953125-26.035157-3.632812-9.347656-6.121094-20.035156-6.832031-35.679687-.722656-15.679687-.890625-20.6875-.890625-60.609375s.167969-44.929688.886719-60.605469c.710937-15.648437 3.195312-26.332031 6.828125-35.683593 3.691406-9.808594 9.480468-18.695313 16.960937-26.035157 7.339844-7.480469 16.226563-13.265625 26.035157-16.957031 9.351562-3.632812 20.035156-6.117188 35.683593-6.832031 15.675781-.714844 20.683594-.886719 60.605469-.886719s44.929688.171875 60.605469.890625c15.648437.710937 26.332031 3.195313 35.683593 6.824219 9.808594 3.691406 18.695313 9.480468 26.039063 16.960937 7.476563 7.34375 13.265625 16.226563 16.953125 26.035157 3.636719 9.351562 6.121094 20.035156 6.835938 35.683593.714843 15.675781.882812 20.683594.882812 60.605469s-.167969 44.929688-.886719 60.605469zm0 0"></path>
                                    </svg>
                                </a>
                            </li>

                            <li>
                                <a href="https://www.behance.net/darklionstudio" target="_blank" title="Connect with us on Behance" aria-label="Connect with us on Behance">
                                    <svg width="40px" height="40px" viewBox="0 0 97.75 97.75">
                                        <path d="M39.337,38.729c0-4.611-3.137-4.611-3.137-4.611h-1.661H23.294v9.894H35.4C37.492,44.011,39.337,43.342,39.337,38.729z"></path>
                                        <path d="M36.201,50.846H23.294v11.85h12.311c1.852-0.053,5.27-0.633,5.27-5.76C40.875,50.784,36.201,50.846,36.201,50.846z"></path>
                                        <path d="M69.243,44.011c-6.849,0-7.804,6.835-7.804,6.835h14.565C76.005,50.846,76.092,44.011,69.243,44.011z"></path>
                                        <path d="M48.875,0C21.882,0,0,21.883,0,48.875S21.882,97.75,48.875,97.75S97.75,75.867,97.75,48.875S75.868,0,48.875,0z
                                M59.531,28.848h18.294v5.46H59.531V28.848z M50.285,57.486c0,13.533-14.084,13.088-14.084,13.088H23.294h-0.379h-9.787V26.236
                                h9.787h0.379H36.2c7.012,0,12.547,3.871,12.547,11.805c0,7.935-6.766,8.438-6.766,8.438C50.9,46.479,50.285,57.486,50.285,57.486z
                                M69.33,64.479c7.37,0,7.109-4.77,7.109-4.77h7.803c0,12.659-15.172,11.792-15.172,11.792c-18.207,0-17.036-16.95-17.036-16.95
                                S52.02,37.517,69.068,37.517c17.947,0,15.434,19.245,15.434,19.245H61.527C61.527,65.001,69.33,64.479,69.33,64.479z"></path>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Block>
            <Block classProps={'dl-block-dark dl-services'}>
                <Container>
                    <Row>
                        <div className="dlxs-12 text-center">
                            <h2 data-title="How DarkLion Studio can help you in creating a future-proof brand identity">Our services</h2>
                            <p>We aim to deliver data-driven and human-centered design solutions to businesses of any scale.</p>
                        </div>
                    </Row>

                    <Row>
                        <div className="dllg-3 dlmd-3 dlsm-6 dlxs-12 dlxss-12">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="dl-card-list dlxs-center">
                                    <i className="dl-icon w-90">
                                        <svg className="amin-icon" width="100" height="100" viewBox="0 0 137 138" fill="none">
                                            <path className="amin-path" d="M89.9197 48.0763C102.358 60.515 111.026 74.23 115.04 86.2738C119.097 98.4444 118.234 108.267 112.753 113.748C107.272 119.229 97.4492 120.092 85.2786 116.035C73.2348 112.021 59.5198 103.353 47.0812 90.9149C34.6425 78.4762 25.9753 64.7612 21.9607 52.7174C17.9038 40.5468 18.7666 30.7239 24.2477 25.2428C29.7287 19.7618 39.5516 18.899 51.7223 22.9558C63.766 26.9704 77.4811 35.6377 89.9197 48.0763Z" stroke="white" strokeWidth="4"></path>
                                            <path className="amin-path" d="M89.9198 90.9158C77.4811 103.354 63.7661 112.022 51.7223 116.036C39.5517 120.093 29.7288 119.23 24.2478 113.749C18.7667 108.268 17.9039 98.4453 21.9607 86.2747C25.9753 74.2309 34.6426 60.5159 47.0812 48.0773C59.5199 35.6386 73.2349 26.9714 85.2787 22.9568C97.4493 18.8999 107.272 19.7627 112.753 25.2438C118.234 30.7248 119.097 40.5477 115.04 52.7184C111.026 64.7621 102.358 78.4772 89.9198 90.9158Z" stroke="white" strokeWidth="4"></path>
                                        </svg>
                                    </i>

                                    <h5 className="mB10 mT20 dlxs-mT5">Strategy</h5>
                                    <ul className="list">
                                        <li>Brand Strategy</li>
                                        <li>Digital Product Design <span className="line-break">Strategy</span></li>
                                        <li>Design Process Consultancy</li>
                                        <li>Design Thinking</li>
                                    </ul>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div className="dllg-3 dlmd-3 dlsm-6 dlxs-12 dlxss-12">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="dl-card-list dlxs-center">
                                    <i className="dl-icon w-90">
                                        <svg className="amin-icon" width="100" height="100" viewBox="0 0 137 137" fill="none">
                                            <path className="amin-path" d="M34 93.5593L103 54.7737L34 10.5254" stroke="white" strokeWidth="4"></path>
                                            <path d="M35.168 127.474V46.7793L102.414 87.1268L35.168 127.474Z" stroke="white" strokeWidth="4"></path>
                                        </svg>
                                    </i>

                                    <h5 className="mB10 mT20 dlxs-mT5">Brand Design</h5>
                                    <ul className="list">
                                        <li>Brand Research</li>
                                        <li>Brand Development</li>
                                        <li>Brand Activation</li>
                                        <li>Brand Management</li>
                                        <li>Brand Analysis</li>
                                        <li>Website Design</li>
                                    </ul>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div className="dllg-3 dlmd-3 dlsm-6 dlxs-12 dlxss-12">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="dl-card-list dlxs-center">
                                    <i className="dl-icon w-90">
                                        <svg className="amin-icon" width="100" height="100" viewBox="0 0 137 137">
                                            <path className="amin-path" d="M24.1055 24L68.1028 112.849L113.382 24"></path>
                                            <path d="M13 56.4844L123.207 56.4844" stroke="white" strokeWidth="4"></path>
                                        </svg>
                                    </i>

                                    <h5 className="mB10 mT20 dlxs-mT5">Digital Product Design</h5>
                                    <ul className="list">
                                        <li>UI/UX Audit &amp; Improvements</li>
                                        <li>UX Research</li>
                                        <li>User Experience Design</li>
                                        <li>User Interface Design</li>
                                        <li>Interaction Design</li>
                                        <li>Digital Prototyping</li>
                                        <li>UX Writing</li>
                                        <li>Design System</li>
                                    </ul>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div className="dllg-3 dlmd-3 dlsm-6 dlxs-12 dlxss-12">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="dl-card-list dlxs-center">
                                    <i className="dl-icon w-90">
                                        <svg className="amin-icon" width="100" height="100" viewBox="0 0 137 137">
                                            <path className="amin-path" d="M113 25L25 113V25L113 113"></path>
                                        </svg>
                                    </i>

                                    <h5 className="mB10 mT20 dlxs-mT5">Digital Marketing</h5>
                                    <ul className="list">
                                        <li>Market Research</li>
                                        <li>Search Engine Optimization</li>
                                        <li>Social Media Marketing</li>
                                        <li>Online Reputation Management (ORM)</li>
                                        <li>PPC</li>
                                    </ul>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div className="dlxs-12 text-center dl-cta">
                            <a href="/services" title="View Our Services" className="dl-button white mT30">
                                View Our Services
                                <i>
                                    <svg width="20px" height="20px" viewBox="0 0 268.832 268.832">
                                        <path d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,17.678l58.661,58.661H12.5
                                    c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,58.661c-4.882,4.882-4.882,12.796,0,17.678
                                    c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z"></path>
                                    </svg>
                                </i>
                            </a>
                        </div>
                    </Row>
                </Container>
            </Block>

            <Block classProps={'dl-about-slider dl-block-white _overflowUpto1199'}>
                <Container>
                    <Row>

                        <div class="dlxs-12 text-center">
                            <h2>About us</h2>
                            <p class="font-l">We are a brand design, digital product design & digital marketing studio in Punjab, India. Our vision is to impact <span class="line-break">"n" lives with our products, and services.</span></p>
                        </div>
                    </Row>
                </Container>


                <div class="dl-container-fluid mT40 _paddingRight0Above599">
                    <Slider slides={slides} options={options} />

                    <div class="dl-cta mT40 text-center _slider_btn">
                        <Link to="/about" title="Explore about us" class="dl-button">
                            Explore About Us
                            <i>
                                <svg width="20px" height="20px" viewBox="0 0 268.832 268.832">
                                    <path d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,17.678l58.661,58.661H12.5
                            c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,58.661c-4.882,4.882-4.882,12.796,0,17.678
                            c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z"></path>
                                </svg>
                            </i>
                        </Link>

                    </div>
                </div>
            </Block>

            <Block classProps={'dl-block-dark dl-clients dl-clients_grid'}>
                <Container>
                    <Row>
                        <div className="dlxs-12 text-center">
                            <h3>Our valuable clients</h3>
                            <p className="font-l">Who trusted us and allowed us to work on their ideas &amp; build around their vision.</p>

                            <div className="dl-clients-slider">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="dl-row dl-clients-items">
                                        <div className='dl-clients-item'>
                                            <img src={require('../assets/img/client-logos/nops01.png')} alt="Client nOps" />
                                        </div>


                                        <div className="dl-clients-item">
                                            <img src={require('../assets/img/client-logos/xops01.png')} alt="Client nOps" />
                                        </div>

                                        <div className="dl-clients-item">
                                            <img src={require('../assets/img/client-logos/nclouds01.png')} alt="Client nClouds" />
                                        </div>

                                        <div className="dl-clients-item">
                                            <img src={require('../assets/img/client-logos/alphav01.png')} alt="Client Alphav" />
                                        </div>

                                        <div className="dl-clients-item">
                                            <img src={require('../assets/img/client-logos/enforkcreations01.png')} alt="Client Enfork Creations" />
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </Row>
                </Container>
            </Block>

            {data ? (
                <section class="dl-block dl-blog" id="dl-blog">
                    <div class="dl-container">
                        <div class="text-center">
                            <h2>DarkLion Edu.</h2>
                            <p class="font-l">Sharing knowledge with businesses and creators to help build better brands.</p>
                        </div>
                        <div class="dl-row">
                            <Swiper ref={swiperElRef}
                                centeredSlides={false}
                                spaceBetween={20}
                                navigation={true}
                                autoplay={false}
                                pagination={true} className="swiper mySwiper blogSwiper"
                                breakpoints={{
                                    576: {
                                        // width: 576,
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        // width: 768,
                                        slidesPerView: 2,
                                    },
                                }}
                            >
                                {data.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div class="dlmd-12">
                                            <a href={item.link} class="dl-blog-card hover-scale anchor-none mT40 block dlxs-center" title={item.title} target="_blank">
                                                {item.image ? (
                                                    <img src={item.image} alt={item.title} className="dl-img-responsive" />
                                                )
                                                    : ""}
                                                {item.categories ? (
                                                    <p class="font-m mT30">
                                                        {JSON.stringify(item.categories).replace(/[\[\]"]/g, ' ')}
                                                    </p>
                                                )
                                                    : ""}
                                                {item.title ? (
                                                    <h4>{item.title}</h4>
                                                )
                                                    : ""}
                                                {item.excerpt ? (
                                                    <p>{item.excerpt.substring(0, 100)} ...</p>
                                                )
                                                    : ""}
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div class="dlxs-12 text-center mT40 dl-cta">
                                <a href="https://edu.darklion.studio/" title="Read More" class="dl-button" target="_blank">
                                    Read More
                                    <i>
                                        <svg width="20px" height="20px" viewBox="0 0 268.832 268.832">
                                            <path d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,17.678l58.661,58.661H12.5
                                        c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,58.661c-4.882,4.882-4.882,12.796,0,17.678
                                        c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z"></path>
                                        </svg>
                                    </i>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            ) :
                null
            }

            <FootCta />
        </Fragment >
    );
};

export default HomePage