import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/img/brand/dark-logo.svg';
import CaveGif from '../../assets/img/cave.gif';
import config from '../../config';
function Header() {
    const [sidebarToggleStatus, updateSidebarToggleStatus] = useState('');
    const [hamburgerStatus, updateHamburgerStatus] = useState('');
    const enviroment = process.env.REACT_APP_ENV || 'development';

    const checkScreenSize = () => {
        const dropMenus = document.querySelectorAll(".dl_drop-menu");
        var dropBtn, dropOption, dropMenuBtnText;
        // Get the current window width
        var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        dropMenus.forEach((dropMenu) => {
            dropBtn = dropMenu.querySelector(".dl_drop-menu_btn");
            dropOption = dropMenu.querySelectorAll(".dl_drop-menu_option");
            dropMenuBtnText = dropMenu.querySelector(".dl_drop-menu_btn_text");
            // Check if the screen size is more than 1024 pixels
            if (windowWidth > 1024) {
                // ===================================================== Start ==================================================//
                dropBtn.addEventListener("mouseenter", () => {
                    dropMenu.classList.add("activeDrop");
                });

                dropMenu.addEventListener("mouseleave", () => {
                    dropMenu.classList.remove("activeDrop");
                });


                // ===================================================== End ==================================================//
            }

            if (windowWidth <= 1024) {
                // ===================================================== Start ==================================================//

                dropBtn.addEventListener("click", () =>
                    dropMenu.classList.toggle("activeDrop")
                );
                // ===================================================== End ==================================================//
            }
        })
    }

    const location = useLocation();

    useEffect(() => {
        checkScreenSize();
        
        const toggleSidebarOnRouteChange = () => {
            updateSidebarToggleStatus('');
            updateHamburgerStatus('');
        };

        toggleSidebarOnRouteChange();

        return () => {
            // Clean up function if necessary
        };
    }, [location.pathname]); 

    const toggleSidebar = () => {
        if (sidebarToggleStatus === '') {
            updateSidebarToggleStatus('active');
            updateHamburgerStatus('is-active');
        } else {
            updateSidebarToggleStatus('');
            updateHamburgerStatus('');
        }
    };

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleLogoScroll = () => {
        const scrollY = window.scrollY;

        if (scrollY >= 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
        };

        window.addEventListener('scroll', handleLogoScroll);

        // Clean up the event listener
        return () => {
        window.removeEventListener('scroll', handleLogoScroll);
        };
    }, []);

    return (
        <Fragment>
            <header className={`${scrolled ? 'scrolled' : ''} nav-down`}>
                <div className="dl-container-fluid">
                    <div className="dl-row">
                        <div className="dlxs-12">
                            <div className="d-flex flex-ac">
                                <div className="dl-logo">
                                    <Link to={config[enviroment].frontendBaseUrl} aria-label="DarkLion Studio home page" title="DarkLion Studio home page">
                                        <img src={Logo} alt="DarkLion Studio Logo" className="logo" />
                                    </Link>

                                </div>

                                <nav className="hidden-mobile flex-grow-1">
                                    <ul className="d-flex flex-ac _nav_items">
                                        <li>
                                            <div className="dl_drop-menu">
                                                <div className="dl_drop-menu_btn">
                                                    <a className="dl_drop-menu_btn_text">How We Help Brands?</a>
                                                    <span className="dl_drop-menu_btn_chevron-down_icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="10" viewBox="0 0 19 10" fill="none">
                                                            <path d="M10.0733 9.29723L18.3233 1.68185C18.6162 1.41149 18.6162 0.973126 18.3233 0.702768C18.0304 0.432411 17.5555 0.432411 17.2626 0.702768L9.54297 7.82861L1.8233 0.702768C1.53041 0.432411 1.05552 0.432411 0.762633 0.702768C0.469746 0.973126 0.469746 1.41149 0.762633 1.68185L9.01264 9.29726C9.30552 9.56758 9.78041 9.56758 10.0733 9.29723Z" fill="black"></path>
                                                        </svg>
                                                    </span>
                                                </div>

                                                <div className="dl_drop-menu_options" data-position="left">
                                                    <Link to="/process" className="dl_drop-menu_option">
                                                        <div className="dl_drop-menu_option_content">
                                                            <span className="dl_drop-menu_option_link">
                                                                Our Process
                                                            </span>
                                                            <p className="dl_drop-menu_option_typo">
                                                                Learn about our process and how we help brands.
                                                            </p>
                                                        </div>
                                                        <span className="dl_drop-menu_option_media">
                                                            <svg className="dl_drop-menu_option_icon" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                                                <path d="M10.1087 8H27V23.9783" stroke="black" strokeWidth="2"></path>
                                                                <path d="M27 8L8.5 25.5" stroke="black" strokeWidth="2"></path>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                    <Link to="/services" className="dl_drop-menu_option">
                                                        <div className="dl_drop-menu_option_content">
                                                            <span className="dl_drop-menu_option_link">
                                                                Our Services
                                                            </span>
                                                            <p className="dl_drop-menu_option_typo">
                                                                From strategizing to making it market-fit, we are there for you to provide the services your business needs!
                                                            </p>
                                                        </div>
                                                        <span className="dl_drop-menu_option_media">
                                                            <svg className="dl_drop-menu_option_icon" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                                                <path d="M10.1087 8H27V23.9783" stroke="black" strokeWidth="2"></path>
                                                                <path d="M27 8L8.5 25.5" stroke="black" strokeWidth="2"></path>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                    <Link to="/work" className="dl_drop-menu_option">
                                                        <div className="dl_drop-menu_option_content">
                                                            <span className="dl_drop-menu_option_link">
                                                                Our Work
                                                            </span>
                                                            <p className="dl_drop-menu_option_typo">
                                                                Our valued clients &amp; their brands, tell their stories through our collaboration.
                                                            </p>
                                                        </div>
                                                        <span className="dl_drop-menu_option_media">
                                                            <svg className="dl_drop-menu_option_icon" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                                                <path d="M10.1087 8H27V23.9783" stroke="black" strokeWidth="2"></path>
                                                                <path d="M27 8L8.5 25.5" stroke="black" strokeWidth="2"></path>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="dl_drop-menu">
                                                <div className="dl_drop-menu_btn">
                                                    <a className="dl_drop-menu_btn_text">Studio</a>
                                                    <span className="dl_drop-menu_btn_chevron-down_icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="10" viewBox="0 0 19 10" fill="none">
                                                            <path d="M10.0733 9.29723L18.3233 1.68185C18.6162 1.41149 18.6162 0.973126 18.3233 0.702768C18.0304 0.432411 17.5555 0.432411 17.2626 0.702768L9.54297 7.82861L1.8233 0.702768C1.53041 0.432411 1.05552 0.432411 0.762633 0.702768C0.469746 0.973126 0.469746 1.41149 0.762633 1.68185L9.01264 9.29726C9.30552 9.56758 9.78041 9.56758 10.0733 9.29723Z" fill="black"></path>
                                                        </svg>
                                                    </span>
                                                </div>

                                                <div className="dl_drop-menu_options" data-position="left">
                                                    <Link to="/about" className="dl_drop-menu_option">
                                                        <div className="dl_drop-menu_option_content">
                                                            <span className="dl_drop-menu_option_link">
                                                                About Our Studio
                                                            </span>
                                                            <p className="dl_drop-menu_option_typo">
                                                                Learn about our story and where we are headed, inspired by our values, team, and a woman.
                                                            </p>
                                                        </div>
                                                        <span className="dl_drop-menu_option_media">
                                                            <svg className="dl_drop-menu_option_icon" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                                                <path d="M10.1087 8H27V23.9783" stroke="black" strokeWidth="2"></path>
                                                                <path d="M27 8L8.5 25.5" stroke="black" strokeWidth="2"></path>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                    <Link to="/careers" className="dl_drop-menu_option">
                                                        <div className="dl_drop-menu_option_content">
                                                            <span className="dl_drop-menu_option_link">
                                                                Careers at DarkLion Studio
                                                            </span>
                                                            <p className="dl_drop-menu_option_typo">
                                                                Explore your dream career &amp; job opportunities at DarkLion Studio for which you are passionate.
                                                            </p>
                                                        </div>
                                                        <span className="dl_drop-menu_option_media">
                                                            <svg className="dl_drop-menu_option_icon" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                                                <path d="M10.1087 8H27V23.9783" stroke="black" strokeWidth="2"></path>
                                                                <path d="M27 8L8.5 25.5" stroke="black" strokeWidth="2"></path>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="dl_drop-menu">
                                                <div className="dl_drop-menu_btn">
                                                    <span className="dl_drop-menu_btn_icon">
                                                        <img src={CaveGif} alt="Static GIF" />
                                                    </span>
                                                    <a className="dl_drop-menu_btn_text">Cave</a>
                                                    <span className="dl_drop-menu_btn_chevron-down_icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="10" viewBox="0 0 19 10" fill="none">
                                                            <path d="M10.0733 9.29723L18.3233 1.68185C18.6162 1.41149 18.6162 0.973126 18.3233 0.702768C18.0304 0.432411 17.5555 0.432411 17.2626 0.702768L9.54297 7.82861L1.8233 0.702768C1.53041 0.432411 1.05552 0.432411 0.762633 0.702768C0.469746 0.973126 0.469746 1.41149 0.762633 1.68185L9.01264 9.29726C9.30552 9.56758 9.78041 9.56758 10.0733 9.29723Z" fill="black"></path>
                                                        </svg>
                                                    </span>
                                                </div>

                                                <div className="dl_drop-menu_options" data-position="right">
                                                    <a href="https://edu.darklion.studio/" target="_blank" className="dl_drop-menu_option">
                                                        <div className="dl_drop-menu_option_content">
                                                            <span className="dl_drop-menu_option_link">
                                                                DarkLion Edu.
                                                            </span>
                                                            <p className="dl_drop-menu_option_typo">
                                                                Sharing knowledge with learners and businesses to help build better brands.
                                                            </p>
                                                        </div>
                                                        <span className="dl_drop-menu_option_media">
                                                            <svg className="dl_drop-menu_option_icon" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                                                <path d="M10.1087 8H27V23.9783" stroke="black" strokeWidth="2"></path>
                                                                <path d="M27 8L8.5 25.5" stroke="black" strokeWidth="2"></path>
                                                            </svg>
                                                        </span>
                                                    </a>
                                                    <a href="https://thebrandconcept.darklion.studio/" target="_blank" className="dl_drop-menu_option">
                                                        <div className="dl_drop-menu_option_content">
                                                            <span className="dl_drop-menu_option_link">
                                                                The Brand Concept
                                                            </span>
                                                            <p className="dl_drop-menu_option_typo">
                                                                Providing resources and assets for the brands &amp; businesses that are building to last.
                                                            </p>
                                                        </div>
                                                        <span className="dl_drop-menu_option_media">
                                                            <svg className="dl_drop-menu_option_icon" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                                                <path d="M10.1087 8H27V23.9783" stroke="black" strokeWidth="2"></path>
                                                                <path d="M27 8L8.5 25.5" stroke="black" strokeWidth="2"></path>
                                                            </svg>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="dl_drop-menu">
                                                <div className="dl_drop-menu_btn">
                                                    <a className="dl_drop-menu_btn_text">Initiatives</a>
                                                    <span className="dl_drop-menu_btn_chevron-down_icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="10" viewBox="0 0 19 10" fill="none">
                                                            <path d="M10.0733 9.29723L18.3233 1.68185C18.6162 1.41149 18.6162 0.973126 18.3233 0.702768C18.0304 0.432411 17.5555 0.432411 17.2626 0.702768L9.54297 7.82861L1.8233 0.702768C1.53041 0.432411 1.05552 0.432411 0.762633 0.702768C0.469746 0.973126 0.469746 1.41149 0.762633 1.68185L9.01264 9.29726C9.30552 9.56758 9.78041 9.56758 10.0733 9.29723Z" fill="black"></path>
                                                        </svg>
                                                    </span>
                                                </div>

                                                <div className="dl_drop-menu_options" data-position="right">
                                                    <Link to="/darklion-aid" className="dl_drop-menu_option">
                                                        <div className="dl_drop-menu_option_content">
                                                            <span className="dl_drop-menu_option_link">
                                                                DarkLion Aid.
                                                            </span>
                                                            <p className="dl_drop-menu_option_typo">
                                                                Our small initiative DarkLion Aid - where we all believe in “paying it forward” and helping people in need.
                                                            </p>
                                                        </div>
                                                        <span className="dl_drop-menu_option_media">
                                                            <svg className="dl_drop-menu_option_icon" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                                                <path d="M10.1087 8H27V23.9783" stroke="black" strokeWidth="2"></path>
                                                                <path d="M27 8L8.5 25.5" stroke="black" strokeWidth="2"></path>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <Link to="/contact" aria-label="Contact DarkLion Studio" title="Contact us" className="dl-button small">Contact us</Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className={`hamburger hamburger--spring-r transition ${hamburgerStatus}`} onClick={() => toggleSidebar()}>
                <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                </span>
            </div>

            <nav className={`dl-mobile-nav text-center dl-block-dark ${sidebarToggleStatus}`}>
                <div className="links mB60">
                    <Link to="/process" aria-label="DarkLion Studio process" title="Process" className="dl-anchor">Process</Link>

                    <Link to="/work" aria-label="DarkLion Studio Work" title="Work">Work</Link>

                    <Link to="/services" aria-label="DarkLion Studio Services" title="Services">Services</Link>

                    <Link to="/about" aria-label="About DarkLion Studio" title="About">About</Link>

                    <Link to="/careers" aria-label="Careers at DarkLion Studio" title="Careers">Careers</Link>

                    <Link to='https://darklion.studio/brand-design-partner' target="_blank" aria-label='DarkLion Edu' title='DarkLion Edu' className='link'>Brand Partner Page</Link>

                    <Link to='https://thebrandconcept.darklion.studio/' target="_blank" aria-label='DarkLion Edu' title='DarkLion Edu' className='link'>The Brand Concept</Link>

                    <Link to='https://edu.darklion.studio/' target="_blank" aria-label='DarkLion Edu' title='DarkLion Edu' className='link'>DarkLion Edu.</Link>

                    <Link to="/darklion-aid" aria-label='DarkLion Aid' title='DarkLion Aid' className='link'>DarkLion Aid.</Link>
                </div>

                <p className="mB30 font-n">
                    <a href="mailto:roar@darklion.studio" className="dl-anchor white">roar@darklion.studio</a>
                </p>

                <div className="social">
                    <ul className="dl-social d-flex dlsm-jcc white">
                        <li>
                            <a href="https://www.linkedin.com/company/darklionstudio" target="_blank" title="Connect with us on LinkedIn" aria-label="Connect with us on LinkedIn">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M20 40C31.0457 40 40 31.0459 40 20C40 8.9541 31.0457 0 20 0C8.95432 0 0 8.9541 0 20C0 31.0459 8.95432 40 20 40ZM9.78149 15.4697H14.5754V30.248H9.78149V15.4697ZM27.6331 15.6426L27.5761 15.625C27.545 15.6143 27.5138 15.6035 27.4805 15.5947L27.3589 15.5693L27.2861 15.5566C27.0318 15.5059 26.7527 15.4697 26.426 15.4697C23.6313 15.4697 21.8584 17.5078 21.2746 18.2949V15.4697H16.4807V30.248H21.2745V22.1865C21.2745 22.1865 24.8972 17.1279 26.4259 20.8428V30.248H31.2185V20.2754C31.2185 18.042 29.6923 16.1816 27.6331 15.6426ZM12.1265 13.4541C13.4212 13.4541 14.4712 12.4023 14.4712 11.1035C14.4712 9.80469 13.4212 8.75195 12.1265 8.75195C10.8316 8.75195 9.78174 9.80469 9.78174 11.1035C9.78174 12.4023 10.8316 13.4541 12.1265 13.4541Z"></path>
                                </svg>
                            </a>
                        </li>

                        <li>
                            <a href="https://www.instagram.com/darklionstudio" target="_blank" title="Connect with us on Instagram" aria-label="Connect with us on Instagram">
                                <svg height="40px" viewBox="0 0 512 512" width="40px">
                                    <path d="m305 256c0 27.0625-21.9375 49-49 49s-49-21.9375-49-49 21.9375-49 49-49 49 21.9375 49 49zm0 0"></path>
                                    <path d="m370.59375 169.304688c-2.355469-6.382813-6.113281-12.160157-10.996094-16.902344-4.742187-4.882813-10.515625-8.640625-16.902344-10.996094-5.179687-2.011719-12.960937-4.40625-27.292968-5.058594-15.503906-.707031-20.152344-.859375-59.402344-.859375-39.253906 0-43.902344.148438-59.402344.855469-14.332031.65625-22.117187 3.050781-27.292968 5.0625-6.386719 2.355469-12.164063 6.113281-16.902344 10.996094-4.882813 4.742187-8.640625 10.515625-11 16.902344-2.011719 5.179687-4.40625 12.964843-5.058594 27.296874-.707031 15.5-.859375 20.148438-.859375 59.402344 0 39.25.152344 43.898438.859375 59.402344.652344 14.332031 3.046875 22.113281 5.058594 27.292969 2.359375 6.386719 6.113281 12.160156 10.996094 16.902343 4.742187 4.882813 10.515624 8.640626 16.902343 10.996094 5.179688 2.015625 12.964844 4.410156 27.296875 5.0625 15.5.707032 20.144532.855469 59.398438.855469 39.257812 0 43.90625-.148437 59.402344-.855469 14.332031-.652344 22.117187-3.046875 27.296874-5.0625 12.820313-4.945312 22.953126-15.078125 27.898438-27.898437 2.011719-5.179688 4.40625-12.960938 5.0625-27.292969.707031-15.503906.855469-20.152344.855469-59.402344 0-39.253906-.148438-43.902344-.855469-59.402344-.652344-14.332031-3.046875-22.117187-5.0625-27.296874zm-114.59375 162.179687c-41.691406 0-75.488281-33.792969-75.488281-75.484375s33.796875-75.484375 75.488281-75.484375c41.6875 0 75.484375 33.792969 75.484375 75.484375s-33.796875 75.484375-75.484375 75.484375zm78.46875-136.3125c-9.742188 0-17.640625-7.898437-17.640625-17.640625s7.898437-17.640625 17.640625-17.640625 17.640625 7.898437 17.640625 17.640625c-.003906 9.742188-7.898437 17.640625-17.640625 17.640625zm0 0"></path>
                                    <path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm146.113281 316.605469c-.710937 15.648437-3.199219 26.332031-6.832031 35.683593-7.636719 19.746094-23.246094 35.355469-42.992188 42.992188-9.347656 3.632812-20.035156 6.117188-35.679687 6.832031-15.675781.714844-20.683594.886719-60.605469.886719-39.925781 0-44.929687-.171875-60.609375-.886719-15.644531-.714843-26.332031-3.199219-35.679687-6.832031-9.8125-3.691406-18.695313-9.476562-26.039063-16.957031-7.476562-7.339844-13.261719-16.226563-16.953125-26.035157-3.632812-9.347656-6.121094-20.035156-6.832031-35.679687-.722656-15.679687-.890625-20.6875-.890625-60.609375s.167969-44.929688.886719-60.605469c.710937-15.648437 3.195312-26.332031 6.828125-35.683593 3.691406-9.808594 9.480468-18.695313 16.960937-26.035157 7.339844-7.480469 16.226563-13.265625 26.035157-16.957031 9.351562-3.632812 20.035156-6.117188 35.683593-6.832031 15.675781-.714844 20.683594-.886719 60.605469-.886719s44.929688.171875 60.605469.890625c15.648437.710937 26.332031 3.195313 35.683593 6.824219 9.808594 3.691406 18.695313 9.480468 26.039063 16.960937 7.476563 7.34375 13.265625 16.226563 16.953125 26.035157 3.636719 9.351562 6.121094 20.035156 6.835938 35.683593.714843 15.675781.882812 20.683594.882812 60.605469s-.167969 44.929688-.886719 60.605469zm0 0"></path>
                                </svg>
                            </a>
                        </li>

                        <li>
                            <a href="https://www.behance.net/darklionstudio" target="_blank" title="Connect with us on Behance" aria-label="Connect with us on Behance">
                                <svg width="40px" height="40px" viewBox="0 0 97.75 97.75">
                                    <path d="M39.337,38.729c0-4.611-3.137-4.611-3.137-4.611h-1.661H23.294v9.894H35.4C37.492,44.011,39.337,43.342,39.337,38.729z"></path>
                                    <path d="M36.201,50.846H23.294v11.85h12.311c1.852-0.053,5.27-0.633,5.27-5.76C40.875,50.784,36.201,50.846,36.201,50.846z"></path>
                                    <path d="M69.243,44.011c-6.849,0-7.804,6.835-7.804,6.835h14.565C76.005,50.846,76.092,44.011,69.243,44.011z"></path>
                                    <path d="M48.875,0C21.882,0,0,21.883,0,48.875S21.882,97.75,48.875,97.75S97.75,75.867,97.75,48.875S75.868,0,48.875,0z
                            M59.531,28.848h18.294v5.46H59.531V28.848z M50.285,57.486c0,13.533-14.084,13.088-14.084,13.088H23.294h-0.379h-9.787V26.236
                            h9.787h0.379H36.2c7.012,0,12.547,3.871,12.547,11.805c0,7.935-6.766,8.438-6.766,8.438C50.9,46.479,50.285,57.486,50.285,57.486z
                            M69.33,64.479c7.37,0,7.109-4.77,7.109-4.77h7.803c0,12.659-15.172,11.792-15.172,11.792c-18.207,0-17.036-16.95-17.036-16.95
                            S52.02,37.517,69.068,37.517c17.947,0,15.434,19.245,15.434,19.245H61.527C61.527,65.001,69.33,64.479,69.33,64.479z"></path>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>

                <p className="font-s">&copy; 2024 DarkLion Studio. All rights reserved.</p>
            </nav>
        </Fragment>
    );
}

export default Header;
