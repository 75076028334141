import React from 'react'

function Block({ children, classProps }) {
    return (
        <div className={"dl-block " + classProps}>
            {children}
        </div>
    )
}

export default Block