import React, { Fragment} from 'react'
import { Link } from 'react-router-dom'
import Block from '../components/Block/Block'
import Container from '../components/Container/Container';
import Row from '../components/Row/Row';
import DarkLionAidLogo from '../assets/img/brand/darklion-aid-logo.svg';
import DarkLionEduLogo from '../assets/img/brand/darklion-edu-logo.svg';
import UnitySvg from '../assets/img/icons/unity.svg';
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from '../components/Slider/Slider.jsx';
import FootCta from '../components/FootCta/FootCta'

import TextTransition from '../components/TextTransition/TextTransition.jsx';
// slider-images
import Team1 from '../assets/img/aboutus/team1.jpg';
import Team2 from '../assets/img/aboutus/team2.jpg';
import Team3 from '../assets/img/aboutus/team3.jpg';
import Team4 from '../assets/img/aboutus/team4.jpg';
import Team5 from '../assets/img/aboutus/team5.jpg';
import Team6 from '../assets/img/aboutus/team6.jpg';

import Initiative1 from '../assets/img/initiatives/oct22/darklion-aid-1.jpg';
import Initiative2 from '../assets/img/initiatives/darklion-aid-initiative1.jpg';
import Initiative3 from '../assets/img/initiatives/darklion-aid-initiative2.jpg';
import Initiative4 from '../assets/img/initiatives/darklion-edu-initiative1.jpg';
import Initiative5 from '../assets/img/initiatives/darklion-edu-initiative2.jpg';
import { MetaInfo } from '../components/Seo/MetaInfo.jsx';


function About() {

    const TeamImages = [
        { image: Team1, alt: "DarkLion Studio Team" },
        { image: Team2, alt: "DarkLion Studio Team" },
        { image: Team3, alt: "DarkLion Studio Team" },
        { image: Team4, alt: "DarkLion Studio Team" },
        { image: Team5, alt: "DarkLion Studio Team" },
        { image: Team6, alt: "DarkLion Studio Team" }
    ]

    const InitiativeImages = [
        { image: Initiative1, alt: "DarkLion Studio Initiative" },
        { image: Initiative2, alt: "DarkLion Studio Initiative" },
        { image: Initiative3, alt: "DarkLion Studio Initiative" },
        { image: Initiative4, alt: "DarkLion Studio Initiative" },
        { image: Initiative5, alt: "DarkLion Studio Initiative" },
    ]

    const options = {
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 30,
        navigation: true,
        autoplay: true,
        pagination: true,
        breakpoints: {
            576: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 1.5,
            },
        },
    };


    const texts = ['Equal.', 'Unique.', 'Fearless.', 'DarkLions & DarkLionesses.'];
    const interval = 2000;


    return (
        <Fragment>
            <MetaInfo page_name="about" />

            <Block classProps={'dl-page-banner dl-block-dark text-center'}>
                <Container>
                    <Row>
                        <div className="dlxs-12 dlsm-12 dllg-12">
                            <ScrollAnimation animateIn="fadeInUp">
                                <h1 className="dl-page-title font-body">About DarkLion Studio</h1>
                                <h2 className="mT0 mB20 font-w-600 font-62">We’re a pride of DarkLions &amp; DarkLionesses</h2>
                                <p className="font-l">DarkLion Studio is a brand design, digital product design, and digital marketing studio vested in Punjab, India. We are a team of rational and emotional thinkers. Who adventitiously gathered together to fulfill a vision, that we could impact many lives through our work.</p>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <img src={require('../assets/img/team/parkash-kaur-soul-darklion-studio.jpg')} alt="DarkLion Studio Inspiration" className="dl-img-responsive" />
                            </ScrollAnimation>
                            <div className="dlxs-12 dlsm-12 dllg-12">
                                <div className="dl-mission">
                                    <ScrollAnimation animateIn="fadeInUp" class="text-center">
                                        <img src={UnitySvg} alt="Icon" />
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <p className="font-l">What we stand for</p>
                                        <h2>Building life-changing brands, businesses &amp; digital products.</h2>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </Block >
            <section class="dl-block text-center"> 
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12 dlsm-12 dllg-12">
                            <h3>Our values</h3>
                            <p class="font-l">Our beliefs & values that back us in course around our vision</p>
                        </div>

                        <div class="dllg-4 dlmd-4 dlsm-4 dlxs-12">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-icon-card delay-1 mT30 dlxs-m0 dlxss-pB10">
                                    <i class="dl-icon">
                                        <svg width="130" height="130" viewBox="0 0 137 137" fill="none">
                                            <path d="M68 19V117" stroke="black" stroke-width="3" />
                                            <path d="M19 68L117 68" stroke="black" stroke-width="3" />
                                        </svg>
                                    </i>
                                    <h4 class="mT20 dlxs-mT5">Positive attitude</h4>
                                    <p class="font-l">We believe in having the right attitude and personality to be a differentiator.</p>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div class="dllg-4 dlmd-4 dlsm-4 dlxs-12">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-icon-card delay-3 mT30 dlxs-m0 dlxss-pB10">
                                    <i class="dl-icon">
                                        <svg width="130" height="130" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M68.549 25C60.1787 35.4824 48.4604 64.1446 68.549 94.9346C76.6845 83.3571 88.0741 53.1616 68.549 25Z" stroke="black" stroke-width="3" />
                                            <path d="M30.7175 34.5802C29.0346 47.8885 33.9662 78.4584 67.155 94.2724C68.0668 80.1517 62.0558 48.4443 30.7175 34.5802Z" stroke="black" stroke-width="3" />
                                            <path d="M106.23 34.8147C93.6056 39.3507 68.5972 57.6106 69.5556 94.3619C82.554 88.7703 108.087 69.0325 106.23 34.8147Z" stroke="black" stroke-width="3" />
                                            <path d="M131.608 60.8891C118.345 58.8815 87.6639 63.0642 71.0433 95.8564C85.1375 97.1132 116.982 91.8792 131.608 60.8891Z" stroke="black" stroke-width="3" />
                                            <path d="M5.54598 60.855C10.4389 73.3451 29.4019 97.8246 66.1111 95.8223C60.1524 82.988 39.6972 58.0266 5.54598 60.855Z" stroke="black" stroke-width="3" />
                                            <path d="M69.9199 86.9023L69.9199 111.45" stroke="black" stroke-width="3" />
                                        </svg>
                                    </i>
                                    <h4 class="mT20 dlxs-mT5">Patience</h4>
                                    <p class="font-l">We believe patience is the key that opens the door to success.</p>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div class="dllg-4 dlmd-4 dlsm-4 dlxs-12">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-icon-card delay-5 mT30 dlxs-m0 dlxss-pB10">
                                    <i class="dl-icon">
                                        <svg width="130" height="130" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M37.3866 76.2615C26.985 69.0405 10.9011 50.6597 23.7845 34.1168C29.919 26.2392 47.549 15.7621 68.9924 36.8739C73.3931 31.0971 86.1951 20.6462 102.198 25.0576C107.632 26.5558 119 31.6974 119 50.6597" stroke="black" stroke-width="3" />
                                            <path d="M96.1967 49.4785L81.1943 64.2489M66.192 79.0192L81.1943 64.2489M112.599 66.0213L87.7954 90.4416M108.199 54.5989L80.1942 82.1702M114.6 79.0192L83.7948 109.348C80.861 112.367 73.2331 116.989 66.192 111.317L48.1891 93.5927C45.9221 91.2294 42.9083 85.1637 48.9893 79.807L63.3915 66.0213L81.1943 64.2489" stroke="black" stroke-width="3" />
                                        </svg>
                                    </i>
                                    <h4 class="mT20 dlxs-mT5">Honesty & discipline</h4>
                                    <p class="font-l">Our stories have honesty & truth in them. They are not just words.</p>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div class="dllg-4 dllg-offset-2 dlmd-4 dlmd-offset-2 dlsm-4 dlsm-offset-0 dlxs-12">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-icon-card delay-1 mT30 dlxs-m0 dlxss-pB10">
                                    <i class="dl-icon">
                                        <svg width="130" height="130" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M35.7807 47.9758C34.204 60.445 38.8246 89.0876 69.9209 103.905C70.7752 90.6741 65.1432 60.9658 35.7807 47.9758Z" stroke="black" stroke-width="3" />
                                            <path d="M101.961 21.5379C90.6169 26.9492 69.0131 46.3147 73.3492 80.4866C84.9432 74.0561 106.897 53.2638 101.961 21.5379Z" stroke="black" stroke-width="3" />
                                            <path d="M73 77L73 120" stroke="black" stroke-width="3" />
                                            <path d="M89.5 49.5L74.0002 76.7422" stroke="black" stroke-width="3" />
                                            <path d="M53 76L68.5954 101.611" stroke="black" stroke-width="3" />
                                        </svg>
                                    </i>
                                    <h4 class="mT20 dlxs-mT5">Continuous learning</h4>
                                    <p class="font-l">It’s the minimum requirement for our success in any field.</p>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div class="dllg-4 dlmd-4 dlsm-4 dlxs-12">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-icon-card delay-3 mT30 dlxs-m0 dlxss-pB10">
                                    <i class="dl-icon">
                                        <svg width="130" height="130" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17 68H54.8983" stroke="black" stroke-width="3" />
                                            <path d="M83.1016 68H121" stroke="black" stroke-width="3" />
                                            <path d="M69 120V82.1017" stroke="black" stroke-width="3" />
                                            <path d="M69 53.8984V16.0001" stroke="black" stroke-width="3" />
                                            <rect x="37.5098" y="36.5078" width="63.8644" height="63.8644" rx="31.9322" stroke="black" stroke-width="3" />
                                        </svg>
                                    </i>
                                    <h4 class="mT20 dlxs-mT5">Aim for the perfection</h4>
                                    <p class="font-l">Our mantra is to achieve excellence.</p>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>

            <section class="dl-block _our-team dl-block-dark dl-aboutUs-slider dl-initiatives">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12 text-center">
                            <h3>The Pride</h3>
                            <p class="font-l">Meet the inspiration & core team of DarkLion Studio</p>
                        </div>

                        <div class="dlxs-12">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-team-inspire-card d-flex dlxs-flex-col grey-bg mT30 mB20 delay-1 dlxss-mB10">
                                    <div class="image">
                                        <img src={require('../assets/img/team/parkash-kaur-darklion-studio.jpg')} alt="Parkash Kaur Darklion Studio" class="dl-img-responsive" />
                                    </div>

                                    <div class="d-flex flex-dc p60 dlsm-center">
                                        <h3>Parkash Kaur</h3>
                                        <p>
                                            Soul & Inspiration of DarkLion Studio
                                            <br />
                                            <br />
                                            She did not have degrees or high qualifications. Still, she taught us some of the most influential life lessons: to see dreams and fight for our dreams, to stand up for what we believe in, to create our own opportunities, to take risks to achieve our goals, to never bow down to anyone and to keep our head held high and live with pride.
                                            <br />
                                            <br />
                                            <i>Our true DarkLioness from which it all started.</i>
                                        </p>
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div class="dlmd-12 dlsm-12 dlxs-12 dlxss-12 hide">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-team-card text-center mT30 delay-1">
                                    <div class="image">
                                        <img src={require('../assets/img/team/Blacks.png')} alt="Blacks" class="dl-img-responsive" />
                                    </div>

                                    <div class="d-flex flex-jcc flex-ac mT20">
                                        <b class="dl-h5-title mR10">Blacks</b>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
                                            <path d="M9.47803 19.2822L21.4978 12.5257L9.47803 4.81763" stroke="white" stroke-width="2" />
                                            <path d="M9.68164 25.1901V11.1331L21.3959 18.1616L9.68164 25.1901Z" stroke="white" stroke-width="2" />
                                        </svg>
                                    </div>
                                    <p>Our Branding Team.</p>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div class="dlmd-6 dlsm-6 dlxs-6 dlxss-12 hide">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-team-card text-center mT30 delay-3">
                                    <div class="image">
                                        <img src={require('../assets/img/team/Alphas.png')} alt="Alphas" class="dl-img-responsive" />
                                    </div>

                                    <div class="d-flex flex-jcc flex-ac mT20">
                                        <b class="dl-h5-title mR10">Alphas</b>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
                                            <path d="M25.2447 5.47437L5.97461 24.7444V5.47437L25.2447 24.7444" stroke="white" stroke-width="2" />
                                        </svg>
                                    </div>
                                    <p>Our Marketing Team.</p>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div class="dlmd-6 dlsm-6 dlxs-6 dlxss-12 hide">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-team-card text-center mT30 delay-3">
                                    <div class="image">
                                        <img src={require('../assets/img/team/Rogues.png')} alt="Rogues" class="dl-img-responsive" />
                                    </div>

                                    <div class="d-flex flex-jcc flex-ac mT20">
                                        <b class="dl-h5-title mR10">Rogues</b>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
                                            <path d="M5.75586 5.25537H25.0259L5.75586 24.5254V5.25537ZM5.75586 5.25537L25.0259 24.5254" stroke="white" stroke-width="2" />
                                        </svg>
                                    </div>
                                    <p>Our Engineering Team.</p>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div class="dlmd-6 dlsm-6 dlxs-6 dlxss-12 hide">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-team-card text-center mT30 delay-5">
                                    <div class="image">
                                        <img src={require('../assets/img/team/Archers.png')} alt="Archers" class="dl-img-responsive" />
                                    </div>

                                    <div class="d-flex flex-jcc flex-ac mT20">
                                        <b class="dl-h5-title mR10">Archers</b>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
                                            <path d="M11.8867 27.3722V2.84668L25.0254 15.1095L11.8867 27.3722Z" stroke="white" stroke-width="2" />
                                            <path d="M6.19336 24.5255V5.69336L18.0182 15.1095L6.19336 24.5255Z" stroke="white" stroke-width="2" />
                                        </svg>
                                    </div>
                                    <p>Our Human Resources Team.</p>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div class="dlmd-6 dlsm-6 dlxs-6 dlxss-12 hide">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="dl-team-card text-center mT30 delay-7">
                                    <div class="image">
                                        <img src={require('../assets/img/team/Aces.png')} alt="Aces" class="dl-img-responsive" />
                                    </div>

                                    <div class="d-flex flex-jcc flex-ac mT20">
                                        <b class="dl-h5-title mR10">Aces</b>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                            <path d="M5.27881 5.25537L14.9133 24.7114L24.8283 5.25537" stroke="white" stroke-width="2" />
                                            <path d="M2.84668 12.3689L26.9796 12.3689" stroke="white" stroke-width="2" />
                                        </svg>
                                    </div>
                                    <p>Our Creative Team.</p>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div class="dlxs-12">
                            <div class="dl-cta mT40 text-center">
                                <Link to="/careers" title="Explore careers" class="dl-button white">
                                    Explore careers
                                    <i>
                                        <svg width="20px" height="20px" viewBox="0 0 268.832 268.832">
                                            <path d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,17.678l58.661,58.661H12.5
                                c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,58.661c-4.882,4.882-4.882,12.796,0,17.678
                                c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z"></path>
                                        </svg>
                                    </i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="dl-block dl-block-white dl-aboutUs-slider _we-are_section _overflowUpto1199">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12 text-left _we-are_typo">
                            <h3> <span>We are</span> <TextTransition texts={texts} interval={interval} /></h3>
                            <p class="font-l">The culture of DarkLion Studio is in its diversity & unity.</p>
                        </div>
                    </div>
                </div>

                <div class="dl-container-fluid mT40 _paddingRight0Above599">
                    <Slider slides={TeamImages} options={options} />
                </div>
            </section>

            <section class="dl-block dl-block-dark dl-aboutUs-slider _about dl-initiatives _overflowUpto1199">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12 text-center">
                            <h3>Our initiatives</h3>
                            <p>We vow to share our success with the community through our initiatives, through ”DarkLion Aid, we want to support those who are unprivileged, and by DarkLion Edu, we want to help unprivileged people with courage and dreams with education and career opportunities.” Every smile and every small positive impact on someone's life will be a success of these initiatives.</p>
                            <ul class="ul-none d-flex dlxs-flex-ac text-center mT40 flex-jcc">
                                <li>
                                    <img src={DarkLionAidLogo} alt="Darklion Aid" />
                                </li>
                                <li>
                                    <img src={DarkLionEduLogo} alt="Darklion Edu" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="dl-container-fluid mT40">
                    <Slider slides={InitiativeImages} options={options} />
                </div>
            </section>

            <section class="dl-block dl-testimonials">
                <div class="dl-container">
                    <div class="dl-row">
                        <div class="dlxs-12">
                            <div class="testimonial">
                                <blockquote class="dlxss-center m0 p0">
                                    <i class="dl-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="42" viewBox="0 0 60 42" fill="none">
                                            <path d="M4.28571 42H17.1429L25.7143 25.2V0H0V25.2H12.8571L4.28571 42ZM38.5714 42H51.4286L60 25.2V0H34.2857V25.2H47.1429L38.5714 42Z" fill="black" />
                                        </svg>
                                    </i>
                                    <div class="content dlxs-mT20 mB50 dlxss-mB20">
                                        We’re a young company with big dreams and aim to do good for the society and community through our work. We want our company's success to be defined by how many lives we will positively impact and touch through our work. Join our mission if you have the dream to do big and touch lots of lives.
                                    </div>

                                    <ul class="ul-none d-flex dlxss-flex-col dlxss-p0 dlxss-center">
                                        <li class="dlxss-p0 pR30"><img src={require('../assets/img/team/sarbjit-founder.jpg')} alt="Sarbjit Singh Founder Darklion Studio" /></li>
                                        <li class="dlxss-p0">
                                            <h6>Sarbjit Singh</h6>
                                            <p>Founder & Design Director</p>
                                        </li>
                                    </ul>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="dl-block _overflowUpto1199 _career_page dl-block-dark dl-block-cta d-flex flex-jc dlxs-flex-col dlsm-p0 dlxs-p0 dlxss-center">
                <Link to="/careers" title="See career opportunities" className="d-flex flex-jc left anchor-none dlxss-flex-col">
                    <div className="text">
                        <p>Want to join our pride?</p>
                        <div className="dl-h4-title">See career opportunities</div>
                    </div>

                    <span className="arrow">
                        <svg width="50" height="50" viewBox="0 0 61 61" fill="none">
                            <path d="M37.7988 50L58.7988 29" stroke="white" strokeWidth="4" />
                            <path d="M37 10L58 31" stroke="white" strokeWidth="4" />
                            <path d="M1 30.2012L58 30.2012" stroke="white" strokeWidth="4" />
                        </svg>
                    </span>
                </Link>

                <Link to="/contact" title="Contact us for business inquiries" className="d-flex right flex-jc anchor-none dlxs-p0 dlxss-center dlxss-flex-col">
                    <div className="text">
                        <p>For business inquiries?</p>
                        <div className="dl-h4-title">Contact us</div>
                    </div>

                    <span className="arrow">
                        <svg width="50" height="50" viewBox="0 0 61 61" fill="none">
                            <path d="M37.7988 50L58.7988 29" stroke="white" strokeWidth="4" />
                            <path d="M37 10L58 31" stroke="white" strokeWidth="4" />
                            <path d="M1 30.2012L58 30.2012" stroke="white" strokeWidth="4" />
                        </svg>
                    </span>
                </Link>
            </div>

            <FootCta />
        </Fragment>
    )
}

export default About